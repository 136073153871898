import * as React from "react"
import "../i18n"
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { hot } from "react-hot-loader"
import {
    Text,
    TextAppearance,
    TextColor,
    Breakpoints,
    Layout,
    Container,
    ContainerPadding,
    ContainerWrap
} from "@volkswagen-onehub/components-core"
import { withGlobalState } from 'react-globally'

class Footer extends React.Component<any, any> {
    
    unicodeToChar(text) {
        return text.replace(/\\u[\dA-F]{4}/gi, 
               function (match) {
                    return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
               });
    }

    render() {
        const showDisclaimer = false; //(this.props.globalState.listBase === "nfz_list") ? false : true;
        return (
            <Layout appearance={{
                [Breakpoints.default]: [
                    {name: '.', columns: 1},
                    {name: 'a', columns: 22},
                    {name: '.', columns: 1},
                ],
                
            }}>
                {(showDisclaimer && this.props.globalState.dataLeasing["Response"]) &&
                    <Container wrap={ContainerWrap.always}>

                        {(this.props.globalState.tabSelectedIndex == 0 || this.props.globalState.vergleichen == true) && 
                            <Container wrap={ContainerWrap.always}>
                            
                                <Container padding={{ top: ContainerPadding.static200}}>
                                <Text appearance={TextAppearance.copy200} color={TextColor.secondary}>
                                    <Trans i18nKey="leasing" />
                                </Text>
                                </Container>
                                <Container padding={{ top: ContainerPadding.static200}}>
                                <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                    <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.globalState.dataLeasing["Response"]["Parameters"]["ProductDisclaimer"]["#cdata-section"]
                                    }}></div>
                                        
                                </Text>
                                </Container>
                                <Container padding={{ top: ContainerPadding.static200}}>
                                <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                    <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.globalState.dataLeasing["Response"]["Parameters"]["GlobalDisclaimer"]["#cdata-section"]
                                    }}></div>
                                </Text>
                                </Container>
                                {/*
                                <Container padding={{ top: ContainerPadding.static200}}>
                                <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                    <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.globalState.dataLeasing["Response"]["Parameters"]["CalculationDisclaimer"]["#cdata-section"]
                                    }}></div>
                                </Text>
                                </Container>
                                */}
                                {/*
                                <Container padding={{ top: ContainerPadding.static200}}>
                                <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                    <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.globalState.dataLeasing["Response"]["Parameters"]["RateDetails"]["#cdata-section"]
                                    }}></div>
                                        
                                </Text>
                                </Container>
                                */}

                            </Container>
                        }
                    </Container>
                }

                {(showDisclaimer && this.props.globalState.dataAC["Response"]) &&
                    <Container wrap={ContainerWrap.always}>

                        {(this.props.globalState.tabSelectedIndex == 1 || this.props.globalState.vergleichen == true) && 
                            <Container wrap={ContainerWrap.always}>
                            
                                <Container padding={{ top: ContainerPadding.static200}}>
                                <Text appearance={TextAppearance.copy200} color={TextColor.secondary}>
                                    <Trans i18nKey="auto_credit" />
                                </Text>
                                </Container>
                                <Container padding={{ top: ContainerPadding.static200}}>
                                <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                    <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.globalState.dataAC["Response"]["Parameters"]["ProductDisclaimer"]["#cdata-section"]
                                    }}></div>
                                        
                                </Text>
                                </Container>
                                <Container padding={{ top: ContainerPadding.static200}}>
                                <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                    <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.globalState.dataAC["Response"]["Parameters"]["GlobalDisclaimer"]["#cdata-section"]
                                    }}></div>
                                        
                                </Text>
                                </Container>
                                {/*
                                <Container padding={{ top: ContainerPadding.static200}}>
                                <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                    <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.globalState.dataAC["Response"]["Parameters"]["CalculationDisclaimer"]["#cdata-section"]
                                    }}></div>
                                        
                                </Text>
                                </Container>
                                */}
                                {/*
                                <Container padding={{ top: ContainerPadding.static200}}>
                                <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                    <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.globalState.dataAC["Response"]["Parameters"]["RateDetails"]["#cdata-section"]
                                    }}></div>
                                        
                                </Text>
                                </Container>
                                */}

                            </Container>
                        }
                    </Container>
                }

                {(showDisclaimer && this.props.globalState.dataCC["Response"]) &&
                    <Container wrap={ContainerWrap.always}>

                        {(this.props.globalState.tabSelectedIndex == 1 || this.props.globalState.vergleichen == true) && 
                            <Container wrap={ContainerWrap.always}>
                            
                                <Container padding={{ top: ContainerPadding.static200}}>
                                    <Text appearance={TextAppearance.copy200} color={TextColor.secondary}>
                                        <Trans i18nKey="classic_credit" />
                                    </Text>
                                </Container>
                                <Container padding={{ top: ContainerPadding.static200}}>
                                    <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                        <div
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.globalState.dataCC["Response"]["Parameters"]["ProductDisclaimer"]["#cdata-section"]
                                        }}></div>
                                            
                                    </Text>
                                </Container>
                                
                                <Container padding={{ top: ContainerPadding.static200}}>
                                    <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                        <div
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.globalState.dataCC["Response"]["Parameters"]["GlobalDisclaimer"]["#cdata-section"]
                                        }}></div>
                                            
                                    </Text>
                                </Container>
                                {/*
                                <Container padding={{ top: ContainerPadding.static200}}>
                                    <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                        <div
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.globalState.dataCC["Response"]["Parameters"]["CalculationDisclaimer"]["#cdata-section"]
                                        }}></div>
                                            
                                    </Text>
                                </Container>
                                */}
                                {/*
                                <Container padding={{ top: ContainerPadding.static200}}>
                                    <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                        <div
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.globalState.dataCC["Response"]["Parameters"]["RateDetails"]["#cdata-section"]
                                        }}></div>
                                    </Text>
                                </Container>
                                */}

                            </Container>
                        }
                    </Container>
                }

                <Container wrap={ContainerWrap.always}>
                    <Container wrap={ContainerWrap.always}>
                        <Container padding={{ top: ContainerPadding.static400}}>
                            <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                <div
                                dangerouslySetInnerHTML={{
                                    __html: i18next.t("emissions_disclaimer")
                                }}></div>
                            </Text>
                        </Container>
                    </Container>
                </Container>
            </Layout>
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(Footer));