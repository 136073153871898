import * as React from "react"
import "../i18n"
import { Trans }  from 'react-i18next'
import i18next from 'i18next'
import { hot } from "react-hot-loader"
import "../assets/scss/App.scss"
import { Info } from '@volkswagen-onehub/icons-core'
import {
    Text,
    TextAppearance,
    TextColor,
    ContainerPadding,
    ContainerHorizontalAlignment,
    Container,
    ContainerWrap,
    ContainerGutter,
    ContainerVerticalAlignment,
    Layout,
    Breakpoints,
    CTA
} from "@volkswagen-onehub/components-core"
import { withGlobalState } from 'react-globally'
import styled from "styled-components"
import InputSet from "./InputSet"

// const Styled = styled.div`
//   margin-left: ${(props) => props.theme.size.grid002};
//   margin-right: ${(props) => props.theme.size.grid002};
// `;

const StyledDivCol1 = styled.div`
    width: ${(props) => props.theme.size.grid012};
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${(props) => props.theme.size.grid006};
    }
`
const StyledDivCol2 = styled.div`
    width: ${(props) => props.theme.size.grid006};
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${(props) => props.theme.size.grid003};
    }
`

const StyledDiv1 = styled.div`
    padding-top: ${(props) => props.theme.size.dynamic0150};
    padding-left: ${(props) => props.theme.size.grid002};
    padding-right: ${(props) => props.theme.size.grid002};
    @media (min-width: ${Breakpoints.b960}px) {
        padding-left: ${(props) => props.theme.size.grid002};
        padding-right: ${(props) => props.theme.size.grid001};
    }
`

const StyledDiv2 = styled.div`
    padding-top: ${(props) => props.theme.size.dynamic0150};
    padding-left: ${(props) => props.theme.size.grid002};
    padding-right: ${(props) => props.theme.size.grid002};
    @media (min-width: ${Breakpoints.b960}px) {
        padding-left: ${(props) => props.theme.size.grid001};
        padding-right: ${(props) => props.theme.size.grid001};
    }
`

class Finazierung extends React.Component<any, any> {

    constructor(props) {
        super(props);     
        this.state = {
        }
      }
    
    formatNumber(numberString) {
        // remove currency symbol
        numberString = numberString.replace(/€/g, "");
        // turn any decimal comma into a dot for further editing
        numberString = numberString.replace(/,/g, ".");
        // turn string into actual number
        let newNumber = parseFloat(numberString).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        return newNumber;
    }

    render() {
        return (
            <Container stretchContent>
                
                <Container wrap={ContainerWrap.always} stretchContent padding={{bottom: ContainerPadding.dynamic0250}}>
                
                {this.props.globalState.loadingAC == true &&
                    <Container padding={{top: ContainerPadding.static400}} horizontalAlign={ContainerHorizontalAlignment.center} verticalAlign={ContainerVerticalAlignment.flexEnd} gutter={ContainerGutter.dynamic0040} wrap={ContainerWrap.never}>
                        <Text appearance={TextAppearance.headline0250} bold={true}>Loading</Text>
                    </Container>
                }
                
                {this.props.globalState.loadingAC == false &&
                <Container padding={{top: ContainerPadding.static400}} horizontalAlign={ContainerHorizontalAlignment.center} verticalAlign={ContainerVerticalAlignment.flexEnd} gutter={ContainerGutter.dynamic0040} wrap={ContainerWrap.never}>
                    <Text appearance={TextAppearance.headline0250} bold={true}><Trans i18nKey="auto_credit" /></Text>
                    <CTA
                        tag="button"
                        emphasis="tertiary"
                        onClick={() => { this.props.setGlobalState({showInfo:"auto_credit"})}}
                        ariaLabel="Info"
                        size="small"
                        icon={<Info />}
                    >
                    </CTA>
                </Container>
                }

                {this.props.globalState.loadingAC == false &&

                <Layout appearance={{
                    [Breakpoints.default]: [
                        {name: 'a', columns: 24},
                        {name: 'b', columns: 24},
                    ],
                    [Breakpoints.b960]: [ 
                        {name: 'a', columns: 12},
                        {name: 'b', columns: 12},
                    ]
                }}>
                    <Container stretchContent horizontalAlign={ContainerHorizontalAlignment.center}>

                        <StyledDiv1>

                            <Container wrap={ContainerWrap.always}>

                                <InputSet
                                    scope = "AutoCredit"
                                    downPaymentTitle = {i18next.t('financing_downpayment')}
                                    trackingMessage = "AutoCredit neu berechnen"
                                    trackingProduct = {this.props.globalState.dataAC}
                                />
                                
                            </Container>

                        </StyledDiv1>
                    </Container>

                    <Container stretchContent horizontalAlign={ContainerHorizontalAlignment.center}>
                        <StyledDiv2>
                            <Container wrap={ContainerWrap.always}>

                                {/* Netto sum */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("net_credit_amount_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["NetCreditAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>

                                {/* Interest Nominal */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_nominal_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["InterestNominal"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>

                                {/* Interest */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["Interest"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>

                                {/* Brutto sum */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("loan_amount")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["GrossCreditAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>

                                {/* Final Rate */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("final_rate_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["FinalRate"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>

                                {/* Monthly Rate */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150} bold>{this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["RateCount"]} {i18next.t("monthly_rate_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200}} stretchContent>
                                                <div className="divider-custom-vw-blue-thick"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150} bold>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["Rate"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200}} stretchContent>
                                                <div className="divider-custom-vw-blue-thick"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>

                                {/* Disclaimer */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}}>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy150} color={TextColor.secondary}><Trans i18nKey="finance_rate_offer_disclaimer" /></Text>
                                        </Container>
                                    </Container>
                                </Container>

                                <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                    <CTA
                                        tag="button"
                                        emphasis="secondary"
                                        onClick={() => {
                                            this.props.setGlobalState({tabSelectedIndex: 2})
                                            this.props.setGlobalState({vergleichen: true})
                                            this.props.tracking("compare", this.props.globalState.dataLeasing, "Finanzierungen vergleichen")
                                        }}
                                        ariaLabel={i18next.t('compare_financing')}
                                    >
                                        <Trans i18nKey="compare_financing" />
                                    </CTA>
                                </Container>

                            </Container>

                        </StyledDiv2>

                    </Container>
                </Layout>
                }

            </Container>
            <Container wrap={ContainerWrap.always}  stretchContent padding={{bottom: ContainerPadding.dynamic0250}}>

            {this.props.globalState.loadingCC == true && 
                <Container padding={{top: ContainerPadding.static400}} horizontalAlign={ContainerHorizontalAlignment.center} verticalAlign={ContainerVerticalAlignment.flexEnd} gutter={ContainerGutter.dynamic0040} wrap={ContainerWrap.never}>
                    <Text appearance={TextAppearance.headline0250} bold={true}>Loading</Text>
                </Container>
            }

            {this.props.globalState.loadingCC == false && 
                <Container padding={{top: ContainerPadding.static400}} horizontalAlign={ContainerHorizontalAlignment.center} verticalAlign={ContainerVerticalAlignment.flexEnd} gutter={ContainerGutter.dynamic0040} wrap={ContainerWrap.never}>
                    <Text appearance={TextAppearance.headline0250} bold={true}><Trans i18nKey="classic_credit" /></Text>
                    <CTA
                        tag="button"
                        emphasis="tertiary"
                        onClick={() => { this.props.setGlobalState({showInfo:"classic_credit"})}}
                        ariaLabel="Info"
                        size="small"
                        icon={<Info />}
                    >
                    </CTA>
                </Container>
            }

            {this.props.globalState.loadingCC == false && 
                <Layout appearance={{
                    [Breakpoints.default]: [
                        {name: 'a', columns: 24},
                        {name: 'b', columns: 24},
                    ],
                    [Breakpoints.b960]: [ 
                        {name: 'a', columns: 12},
                        {name: 'b', columns: 12},
                    ]
                }}>
                    <Container stretchContent horizontalAlign={ContainerHorizontalAlignment.center}>

                        <StyledDiv1>

                            <Container wrap={ContainerWrap.always}>

                                <InputSet
                                    scope = "ClassicCredit"
                                    downPaymentTitle = {i18next.t('financing_downpayment')}
                                    trackingMessage = "ClassicCredit neu berechnen"
                                    trackingProduct = {this.props.globalState.dataCC}
                                />
                                
                            </Container>

                        </StyledDiv1>
                    </Container>

                    <Container stretchContent horizontalAlign={ContainerHorizontalAlignment.center}>

                        <StyledDiv2>

                            <Container wrap={ContainerWrap.always}>

                                {/* Netto Sum */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("net_credit_amount_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["NetCreditAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>

                                {/* Interest Nominal */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_nominal_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["InterestNominal"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>

                                {/* Interest */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["Interest"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>

                                {/* Brutto Sum */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("loan_amount")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["GrossCreditAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>

                                {/* Monthly Rate */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150} bold>{this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["RateCount"]} {i18next.t("monthly_rate_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200}} stretchContent>
                                                <div className="divider-custom-vw-blue-thick"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150} bold>{this.formatNumber(this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["Rate"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200}} stretchContent>
                                                <div className="divider-custom-vw-blue-thick"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>

                                {/* Disclaimer */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}}>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy150} color={TextColor.secondary}><Trans i18nKey="finance_rate_offer_disclaimer" /></Text>
                                        </Container>
                                    </Container>
                                </Container>

                                <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                    <CTA
                                        tag="button"
                                        emphasis="secondary"
                                        onClick={() => {
                                            this.props.setGlobalState({tabSelectedIndex: 2})
                                            this.props.setGlobalState({vergleichen: true})
                                            this.props.tracking("compare", this.props.globalState.dataLeasing, "Finanzierungen vergleichen")
                                        }}
                                        ariaLabel={i18next.t('compare_financing')}
                                    >
                                        <Trans i18nKey="compare_financing" />
                                    </CTA>
                                </Container>

                            </Container>

                        </StyledDiv2>

                </Container>
            </Layout>
        }
        </Container>
                
        </Container>
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(Finazierung));
