import * as React from "react"
import "../i18n"
import { Trans } from 'react-i18next'
import { hot } from "react-hot-loader"
import "../assets/scss/App.scss"
import "../assets/scss/Flickity.scss"
import Flickity from 'react-flickity-component'
import i18next from 'i18next'
import {
    Text,
    TextAppearance,
    Breakpoints,
    Container,
    ContainerPadding,
    ContainerWrap,
    ContainerHorizontalAlignment,
    ContainerVerticalAlignment,
    ThemeProvider,
    CTA
} from "@volkswagen-onehub/components-core"
import { withGlobalState } from 'react-globally'
import styled from "styled-components"
import { Add, CloseCircle, ChevronRight, ChevronLeft } from "@volkswagen-onehub/icons-core"
import CarImage from '../components/CarImages'

const StyledDiv = styled.div`
    
    width: ${props => props.theme.size.grid016};
    margin-right: ${props => props.theme.size.grid001};
    padding-bottom: ${props => props.theme.size.static300};
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${props => props.theme.size.grid006};
    }
    cursor: pointer;
    @media print {
        padding-bottom: 0;
    }
`;

const StyleContainerDiv = styled.div`
    position: relative;
`;

const StyledInnerDiv = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100% - ${props => props.theme.size.static400});
    z-index: -1;
`;

const StyledInnerDivWhite = styled.div`
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: calc(100% - ${props => props.theme.size.static400});
    z-index: -1;
`;

const StyledText = styled.div`
    border-bottom: 1px solid ${props => props.theme.border.color.primary};      
`;

const StyledDivChevronLeft  = styled.div`
   width: ${props => props.theme.size.grid001};
   text-align: left;
   display: block;
`
const StyledDivChevronRight  = styled.div`
   width: ${props => props.theme.size.grid001};
   text-align: right;
   display: block;
`

const StyledDivCarousel  = styled.div`
    width: ${props => props.theme.size.grid016};
        
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${props => props.theme.size.grid020};
    }
`

const StyledDivAnimated = styled.div`
    opacity: 0;
    transition: all 0.1806s;
`

const StyledButton = styled.button`
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
`

class Header extends React.Component<any, any> {    
    constructor(props) {
        super(props)
        this.state = {
            flickityRef: {
                initialIndex: 0
            },
            lastSelectedIndex: 0,
            clickable: false
        }
    }
    requestDomain = this.props.globalState.requestDomain;
    requestDomainElectric = this.props.globalState.requestDomainElectric;

    track() {
        if (this.props.globalState.vergleichen) {
            if (!this.props.globalState.isLive) {
                console.log("selection changed, track comparison")
            }
            this.props.tracking("compare", this.props.globalState.dataAC, "Finanzierungen vergleichen")
        }
        else {if (!this.props.globalState.isLive) {
            console.log("selection changed")
        }
        }
    }
    flickityClick = (item) => {
        this.state.flickityRef.select(item);
        //this.updateProps();
        //this.track()
        console.log("flickity click")
        console.log(this.props.globalState.selectedList)
        console.log(this.props.globalState.selectedModel)
    }

    createList = () => {
        
        let vehicles = i18next.t(this.props.globalState.listBase)
        let selectedList = this.props.globalState.selectedList
        let children = []

        function getFontColor(bgColor, lightColor, darkColor) {
            var rgb = bgColor.replace(/[^\d,]/g, '').split(',');
            return (((rgb[0] * 0.299) + (rgb[1] * 0.587) + (rgb[2] * 0.114)) > 186) ? darkColor : lightColor;
        }
        
        for (let i = 0; i < selectedList.length; i++) {

            children.push(
                <div key={i}>
                    
                    <StyledDiv className="vehicle-list-item">
                        <div onClick={() => { this.flickityClick(i) }}>
                            <StyleContainerDiv>
                                <StyledInnerDiv>
                                    <div style={{width: "100%", height: "100%", backgroundColor: vehicles[selectedList[i]]["background_color"]}}>
                                    </div>
                                </StyledInnerDiv>
                                <Container wrap={ContainerWrap.never} stretchContent>
                                    <Container padding={{left: ContainerPadding.static200, top: ContainerPadding.static200}} horizontalAlign={ContainerHorizontalAlignment.flexStart}>
                                        <ThemeProvider theme="inverted">
                                            
                                        <Text appearance={TextAppearance.headline0200} bold={true}>
                                            <span style={{color: `${getFontColor(vehicles[selectedList[i]]["background_color"], "#ffffff", "#000000")}`}}>
                                                {vehicles[selectedList[i]]["name_label"]}
                                            </span>
                                        </Text>
                                        </ThemeProvider>
                                    </Container>
                                    <Container padding={{right: ContainerPadding.static200, top: ContainerPadding.static200}} horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                        {selectedList.length != 1 &&
                                            <div className={getFontColor(vehicles[selectedList[i]]["background_color"], "#ffffff", "#000000") === "#ffffff" ? "inverted-svg" : ""} onClick={() => {
                                                    selectedList.splice(i, 1)
                                                    this.props.setGlobalState({"selectedList": selectedList})
                                                    if (!this.props.globalState.isLive) {
                                                        console.log("remove vehicle from list, trigger tracking")
                                                    }
                                                    this.props.tracking("add_vehicle", this.props.globalState.dataLeasing, "Fahrzeugliste ändern")
                                                }}>
                                                <CloseCircle />
                                            </div> 
                                        }                                   
                                    </Container>
                                </Container>
                                <CarImage source={vehicles[selectedList[i]]["preview_image_path"]["path"]} alttext={vehicles[selectedList[i]]["name_label"]}></CarImage>
                            </StyleContainerDiv>
                        </div>
                    </StyledDiv>
                </div>
            )
        }

        children.push( 
            <div key={selectedList.length}>
            <StyledDiv>
                <div onClick={() => this.props.setGlobalState({showModal:true})}>
                    <StyleContainerDiv>
                        <StyledInnerDivWhite>
                        </StyledInnerDivWhite>
                        <Container wrap={ContainerWrap.never} stretchContent>
                            <Container padding={{left: ContainerPadding.static200, top: ContainerPadding.static200}} horizontalAlign={ContainerHorizontalAlignment.flexStart}>
                                <ThemeProvider theme="inverted">
                                    <Text appearance={TextAppearance.headline0200} bold={true}>‏‏‎ㅤㅤㅤㅤ ㅤㅤㅤㅤ ㅤ</Text>
                                </ThemeProvider>
                            </Container>
                            <Container padding={{right: ContainerPadding.static200, top: ContainerPadding.static200}} horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                <Add />
                            </Container>
                        </Container>
                        <CarImage source="placeholder" alttext={i18next.t("update_vehicles")}></CarImage>
                    </StyleContainerDiv>
                    <Container padding={{top: ContainerPadding.static300}}>
                        <Text appearance={TextAppearance.copy0100}></Text>
                    </Container>
                </div>
            </StyledDiv>
        </div>
        )
        return children
          
    }

    handleKey = (event) => {
        if (this.state.clickable &&
            // open car selection modal when "add car" slide is in focus
            (event.key === " " || event.code === "Space" ||
            event.key === "Enter" || event.code ==="Enter" )) {
            this.props.setGlobalState({showModal:true})
        }
        else if (this.state.clickable &&
            (event.key === "Tab" || event.code === "Tab")) {
                // fix focus when leaving the "add car" slide
                this.state.flickityRef.previous()
                this.setState({clickable: false})
            }
    }

    componentDidMount() {
        window.addEventListener('keydown', (event) => {this.handleKey(event)})
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', (event) => {this.handleKey(event)})
    }

    render() {
        return (
            <Container wrap={ContainerWrap.always} stretchContent>
                <Container verticalAlign={ContainerVerticalAlignment.center} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.center}>
                    
                    <StyledDivChevronLeft onClick={() => { 
                        this.state.flickityRef.previous();
                     }}>
                         {(parseInt(this.state.flickityRef.selectedIndex) > 0) && 
                            <ChevronLeft />
                         }
                    </StyledDivChevronLeft>
                    
                    <StyledDivCarousel>
                            <StyledDiv>
                                <StyleContainerDiv>
                                    <Container padding={{bottom: ContainerPadding.static150}}>
                                        <StyledDivAnimated id="StyledDivAnimated-title">
                                            <Text appearance={TextAppearance.headline0200} bold={true}>{this.props.globalState.selectedModel["title"]}</Text>
                                        </StyledDivAnimated>
                                    </Container>
                                </StyleContainerDiv>
                            </StyledDiv>
                            <Flickity  className={'carousel header'} // default ''
                                elementType={'div'} // default 'div'
                                options={{
                                    initialIndex: this.props.globalState.flktySelectedIndex, 
                                    wrapAround: false, 
                                    prevNextButtons: false, 
                                    cellAlign: 'left', 
                                    pageDots: false, 
                                    adaptiveHeight: false,

                                }} // takes flickity options {}
                                disableImagesLoaded={false} // default false
                                 
                                flickityRef={(c) => {
                                    
                                    this.setState({flickityRef: c})
                                    
                                    c.on('select', (i) => {
                                        
                                        //console.log(i)
                                        // Remove this block to disallow last add car tile to be selected
                                        // if (i == c.cells["length"] - 1) {
                                        //     this.state.flickityRef.select(c.cells["length"] - 2)
                                        //     return
                                        // }
                                        // if(i == this.state.lastSelectedIndex) {
                                        //     return
                                        // }
                                        // this.setState({lastSelectedIndex: i})
 
                                        let title = document.getElementById('StyledDivAnimated-title')
                                        let disclaimer = document.getElementById('StyledDivAnimated-disclaimer')
                                        title.style.opacity = "0"
                                        disclaimer.style.opacity = "0"

                                        if (!this.props.globalState.isInitialized) {
                                            console.log("initial call")
                                            //product data = false = product from the active tab will be used for tracking
                                            this.props.setGlobalState({ trackingData: ["calculate", false, "Initial Setup", "initial call"] })
                                            // initial call + model selection changes
                                            this.props.setGlobalState({ isInitialized: true })
                                        }
                                        else {
                                            console.log("header triggered update")
                                            //product data = false = product from the active tab will be used for tracking
                                            this.props.setGlobalState({ trackingData: ["compare", false, "Finanzierungen vergleichen", "car changed"] })
                                        }

                                        setTimeout(() => {
                                            if(i == c.cells["length"] - 1) {
                                                this.setState({clickable: true}) // console.log("set clickable: " + this.state.clickable)
                                                return
                                            }
                                            else {
                                                this.setState({clickable: false}) // console.log("set clickable: " + this.state.clickable)
                                            }
                                            this.props.setGlobalState({selectedModel: i18next.t(this.props.globalState.listBase)[this.props.globalState.selectedList[i]]})

                                            title.style.opacity = "1"
                                            disclaimer.style.opacity = "1"
                                        }, 180);
                                    })
                                    
                                    c.on('settle', () => { 
                                        //TO DO
                                        //call the car details api here to update leasing type and calc api to update leasing details to selected car.
                                    })
                                    c.on('staticClick', (e, p, c, i) => {
                                        //console.log("Flickity element clicked, modal selectable? " + this.state.clickable)
                                    })

                                    // c.on( 'scroll', ( e, p ) {
                                    //     let progress = Math.max( 0, Math.min( 1, p ) );
                                    //     console.log(progress * 100 + '%')
                                    // });
                                }}>

                                {this.createList()}

                            </Flickity>
 
                    </StyledDivCarousel>

                    <StyledDivChevronRight onClick={() => { 
                        this.state.flickityRef.next()
                        //console.log("selectedList.length: " + this.props.globalState.selectedList.length);
                    }}>
                        {(this.props.globalState.selectedList.length > 1 && parseInt(this.state.flickityRef.selectedIndex) < this.props.globalState.selectedList.length)  && 
                            <ChevronRight />
                        }
                    </StyledDivChevronRight>

                </Container>
                <Container verticalAlign={ContainerVerticalAlignment.center} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.center}>
                    <StyledDivCarousel>
                        <Container padding={{bottom: ContainerPadding.static300}} horizontalAlign={{
                            [Breakpoints.default]: ContainerHorizontalAlignment.center,
                            [Breakpoints.b960]: ContainerHorizontalAlignment.flexStart
                        }} verticalAlign={ContainerVerticalAlignment.center} stretchContent>

                            <StyledDiv>
                                <StyledDivAnimated id="StyledDivAnimated-disclaimer">
                                    <Container padding={{bottom: ContainerPadding.static250}}>
                                        
                                            <Text appearance={TextAppearance.copy0100}>
                                                
                                                <Text bold>{this.props.globalState.selectedModel["disclaimer_text"]}</Text>
                                            </Text>
                                        
                                    </Container>
                                    
                                    <Container
                                        horizontalAlign={{
                                            [Breakpoints.default]: ContainerHorizontalAlignment.center,
                                            [Breakpoints.b960]: ContainerHorizontalAlignment.flexStart
                                        }}
                                        padding={{bottom: ContainerPadding.static100}}
                                    >
                                        <CTA
                                            tag="a"
                                            emphasis="tertiary"
                                            size="small"
                                            href={this.props.globalState.selectedModel["configurator_link_url"]}
                                            target={"_blank"}
                                            ariaLabel={i18next.t('to_the_configurator')}
                                            onClick={() => { this.props.tracking("cta", this.props.globalState.dataLeasing, {url: `${this.props.globalState.selectedModel["configurator_link_url"]}`, name: "Zum Konfigurator"}) }}
                                        >
                                            <Trans i18nKey="to_the_configurator" />
                                        </CTA>
                                    </Container>
                                </StyledDivAnimated>
                                
                            </StyledDiv>

                            <Container
                                horizontalAlign={{
                                    [Breakpoints.default]: ContainerHorizontalAlignment.center,
                                    [Breakpoints.b960]: ContainerHorizontalAlignment.flexEnd
                                }}
                                verticalAlign={
                                    ContainerVerticalAlignment.flexEnd
                                    }
                            >
                                <CTA
                                    tag="button"
                                    emphasis="secondary"
                                    onClick={() => { this.props.setGlobalState({showModal:true})}}
                                    ariaLabel={i18next.t('add_vehicle')}
                                >
                                    <Trans i18nKey="add_vehicle" />
                                </CTA>
                            </Container>

                        </Container>

                        <Container padding={{bottom: ContainerPadding.static300}} horizontalAlign={{
                            [Breakpoints.default]: ContainerHorizontalAlignment.center,
                            [Breakpoints.b960]: ContainerHorizontalAlignment.flexStart
                        }} verticalAlign={ContainerVerticalAlignment.center} >     

                        </Container>
                    </StyledDivCarousel> 
                </Container>
            </Container>
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(Header));
