import * as React from "react"
import "../i18n"
import { Trans }  from 'react-i18next'
import i18next from 'i18next'
import { hot } from "react-hot-loader"
import "../assets/scss/App.scss"
import {Info} from '@volkswagen-onehub/icons-core'
import {
    Text,
    TextAppearance,
    TextColor,
    ContainerPadding,
    ContainerHorizontalAlignment,
    Container,
    ContainerWrap,
    ContainerGutter,
    ContainerVerticalAlignment,
    Layout,
    Breakpoints,
    CTA
} from "@volkswagen-onehub/components-core"
import { withGlobalState } from 'react-globally'
import styled from "styled-components"
import InputSet from "./InputSet"

// const Styled = styled.div`
//   margin-left: ${(props) => props.theme.size.grid002};
//   margin-right: ${(props) => props.theme.size.grid002};
// `;

const StyledDivCol1 = styled.div`
    width: ${(props) => props.theme.size.grid012};
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${(props) => props.theme.size.grid006};
    }
`
const StyledDivCol2 = styled.div`
    width: ${(props) => props.theme.size.grid006};
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${(props) => props.theme.size.grid003};
    }
`

const StyledDiv1 = styled.div`
    padding-top: ${(props) => props.theme.size.dynamic0150};
    padding-left: ${(props) => props.theme.size.grid002};
    padding-right: ${(props) => props.theme.size.grid002};
    @media (min-width: ${Breakpoints.b960}px) {
        padding-left: ${(props) => props.theme.size.grid002};
        padding-right: ${(props) => props.theme.size.grid001};
    }
`

const StyledDiv2 = styled.div`
    padding-top: ${(props) => props.theme.size.dynamic0150};
    padding-left: ${(props) => props.theme.size.grid002};
    padding-right: ${(props) => props.theme.size.grid002};
    @media (min-width: ${Breakpoints.b960}px) {
        padding-left: ${(props) => props.theme.size.grid001};
        padding-right: ${(props) => props.theme.size.grid001};
    }
`

class Leasing extends React.Component<any, any> {
    constructor(props) {
        super(props);     
    }

    formatNumber(numberString) {
        // remove currency symbol
        numberString = numberString.replace(/€/g, "");
        // turn any decimal comma into a dot for further editing
        numberString = numberString.replace(/,/g, ".");
        // turn string into actual number
        let newNumber = parseFloat(numberString).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        return newNumber;
    }

    render() {
        let leasing_rate_offer_disclaimer = (this.props.globalState.listBase === "nfz_list") ? "nfz_leasing_rate_offer_disclaimer" : "leasing_rate_offer_disclaimer";
        return (
            
            <Container stretchContent>
                
            <Container wrap={ContainerWrap.always} stretchContent padding={{bottom: ContainerPadding.dynamic0250}}>

                {this.props.globalState.loadingLeasing == true &&
                    <Container padding={{top: ContainerPadding.static400}} horizontalAlign={ContainerHorizontalAlignment.center} verticalAlign={ContainerVerticalAlignment.flexEnd} gutter={ContainerGutter.dynamic0040} wrap={ContainerWrap.never}>
                        <Text appearance={TextAppearance.headline0250} bold={true}>Loading</Text>
                    </Container>
                }
                
                {this.props.globalState.loadingLeasing == false &&
                <Container padding={{top: ContainerPadding.static400}} horizontalAlign={ContainerHorizontalAlignment.center} verticalAlign={ContainerVerticalAlignment.flexEnd} gutter={ContainerGutter.dynamic0040} wrap={ContainerWrap.never}>
                    <Text appearance={TextAppearance.headline0250} bold={true}><Trans i18nKey="private_customer" /></Text>
                    <CTA
                        tag="button"
                        emphasis="tertiary"
                        onClick={() => { this.props.setGlobalState({showInfo:"leasing"})}}
                        ariaLabel="Info"
                        size="small"
                        icon={<Info />}
                    >
                    </CTA>
                </Container>
                }

                {this.props.globalState.loadingLeasing == false &&

                <Layout appearance={{
                    [Breakpoints.default]: [
                        {name: 'a', columns: 24},
                        {name: 'b', columns: 24},
                    ],
                    [Breakpoints.b960]: [ 
                        {name: 'a', columns: 12},
                        {name: 'b', columns: 12},
                    ]
                }}>
                    <Container stretchContent horizontalAlign={ContainerHorizontalAlignment.center}>
                        <StyledDiv1>
                            <Container wrap={ContainerWrap.always}>
                                {/* Switch Private/Business Customer 
                                <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                    <Container verticalAlign={ContainerVerticalAlignment.flexEnd} gutter={ContainerGutter.dynamic0040} wrap={ContainerWrap.never} >
                                        <Text appearance={TextAppearance.label0200}><Trans i18nKey="private_customer" /></Text>
                                        <Switch className={'react-switch'} onChange={() => { this.props.setGlobalState({ kunden : !this.props.globalState.kunden }); }} checked={this.props.globalState.kunden} uncheckedIcon={false} checkedIcon={false} height={24} width={60} offColor="#001E50" onColor="#001E50" offHandleColor="#fff" onHandleColor="#fff" handleDiameter={16}/>
                                        <Text appearance={TextAppearance.label0200}><Trans i18nKey="business_customer" /></Text>
                                    </Container>
                                </Container>
                                */}

                                <InputSet
                                    scope = "Leasing"
                                    downPaymentTitle = {i18next.t('leasing_downpayment')}
                                    trackingMessage = "Leasing neu berechnen"
                                    trackingProduct = {this.props.globalState.dataLeasing}
                                />

                            </Container>
                        </StyledDiv1>
                    </Container>

                    <Container stretchContent horizontalAlign={ContainerHorizontalAlignment.center}>
                        <StyledDiv2>
                            <Container wrap={ContainerWrap.always}>

                                {/* Netto sum 
                                {this.props.globalState.listBase === "nfz_list" &&
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("net_credit_amount_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataLeasing["Response"]["Result"][1]["Calculation"]["NetCreditAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>
                                */}

                                {/* Interest Nominal 
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_nominal_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataLeasing["Response"]["Result"][1]["Calculation"]["InterestNominal"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>
                                */}
                                
                                {/* Interest 
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataLeasing["Response"]["Result"][1]["Calculation"]["Interest"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>
                                */}

                                {/* Leasing Sum 
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("loan_amount")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataLeasing["Response"]["Tracking"]["Financing"]["TotalSum"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>                                
                                </Container>*/}

                                {/* Monthly Rate */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150} bold>{this.props.globalState.dataLeasing["Response"]["Result"][1]["Calculation"]["RateCount"]} {i18next.t("monthly_rate_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200}} stretchContent>
                                                <div className="divider-custom-vw-blue-thick"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150} bold>{this.formatNumber(this.props.globalState.dataLeasing["Response"]["Result"][1]["Calculation"]["Rate"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200}} stretchContent>
                                                <div className="divider-custom-vw-blue-thick"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>

                                {/* Disclaimer */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}}>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy150} color={TextColor.secondary}><Trans i18nKey={leasing_rate_offer_disclaimer} /></Text>
                                        </Container>
                                    </Container>
                                </Container>
                                
                                {/* Test 
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}}>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy150} color={TextColor.secondary}>
                                                <b>Test:</b><br/>
                                                {this.props.globalState.dataLeasing["Response"]["Summary"]["DetailGroups"]["DetailGroup"][0]["Detail"][4]["@Label"]} {this.props.globalState.dataLeasing["Response"]["Summary"]["DetailGroups"]["DetailGroup"][0]["Detail"][4]["@Value"]} {this.props.globalState.dataLeasing["Response"]["Summary"]["DetailGroups"]["DetailGroup"][0]["Detail"][4]["@Units"]}
                                            </Text>
                                        </Container>
                                    </Container>
                                </Container>*/}

                                
                                <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                    <CTA
                                        tag="button"
                                        emphasis="secondary"
                                        onClick={() => {
                                            this.props.setGlobalState({tabSelectedIndex: 2})
                                            this.props.setGlobalState({vergleichen: true})
                                            this.props.tracking("compare", this.props.globalState.dataLeasing, "Finanzierungen vergleichen")
                                        }}
                                        ariaLabel={i18next.t('compare_financing')}
                                    >
                                        <Trans i18nKey="compare_financing" />
                                    </CTA>
                                </Container>                            

                            </Container>
                        </StyledDiv2>
                    </Container>
                </Layout>

                }
            </Container>
            
            </Container>
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(Leasing));
