import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

let TranslationDE_DE = require("./i18n/int/de-DE.json");
let TranslationEN_EN = require("./i18n/int/en-EN.json");
let TranslationNL_NL = require("./i18n/int/nl-NL.json");

const resources = {
  de: {
    translation: TranslationDE_DE
  },
  en: {
    translation: TranslationEN_EN
  },
  nl: {
    translation: TranslationNL_NL
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    fallbackLng: 'de',
    returnObjects: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
      transSupportBasicHtmlNodes: true // allow <br/> and simple html elements in translations
    },
    resources
  });

// Log the available languages
// console.log('Available languages:', Object.keys(resources));

// Log the detected and fallback languages
// console.log('i18next.languages:', i18n.languages);

export default i18n;
