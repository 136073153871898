import * as React from "react"
import { hot } from "react-hot-loader"
import { withGlobalState } from 'react-globally'

let timeout = null

class UpdateProps extends React.Component<any, any> {
    constructor(props) {
        super(props);     
    }

    debug = (message: any) => {
        if (!this.props.isLive) console.log(message)
    }

    dataCall(product, callback,loadingIndicator, t0) {
        /* call the updateData function with the current globalState values */
        // this.debug("data call for " + product)
        
        // tbd: update with usecase?
        let requestDomain = this.props.globalState.listBase === "electric_list" ? this.props.globalState.requestDomainElectric : this.props.globalState.requestDomain

        this.props.updateData(requestDomain, product, this.props.globalState.mileage, this.props.globalState.duration, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
            this.props.setGlobalState({ [callback] : data })
            this.props.setGlobalState({ [loadingIndicator]: false })
            this.dataBack(product, t0)
        }).catch(err => {
            this.debug("error in data call:")
            this.debug(err)
            this.props.setGlobalState({ calcErrored: true })
        })
    }

    dataBack(product, t0) {
        this.debug("data back for " + product)
        this.debug(product === "PL" ? this.props.globalState.dataLeasing : this.props.globalState['data' + product])
        // get awareness of the usecase and the current active tab
        let usecase = this.props.globalState.listBase === "electric_list" ? "electric" : "classic"
        let activeTab = this.props.globalState.tabSelectedIndex

        // check if the data belongs to the active tab and all necessary loading indicators are false to remove spinner only once
        if (usecase === "classic") {
            if (activeTab === 0 && product === "PL" && !this.props.globalState.loadingLeasing) {
                this.removeSpinner(product, t0)
            }
            else if (activeTab === 1 && (product === "AC" || product === "CC") && !this.props.globalState.loadingAC && !this.props.globalState.loadingCC) {
                this.removeSpinner(product, t0)
            }
        }
        else if (usecase === "electric") {
            if (activeTab === 0 && product === "PL" && !this.props.globalState.loadingLeasing) {
                this.removeSpinner(product, t0)
            }
            else if (activeTab === 1 && (product === "PL_S" || product === "PL_M" || product === "PL_L") && (!this.props.globalState.loadingPL_S && !this.props.globalState.loadingPL_M && !this.props.globalState.loadingPL_L)) {
                this.removeSpinner(product, t0)
            }
        }
    }

    removeSpinner(product, t0) {
        this.debug("remove spinner (" + product + ")")
        let mintime = 500; // in ms
        let t1 = performance.now();
        let tdiff = t1-t0;
        let wait = (tdiff < mintime) ? (mintime - tdiff) : mintime;
        setTimeout(function() {
            this.props.setGlobalState({showCalcSpinner: false});
            /* Moved tracking to be triggered when data is back; tbd: compare all "loading? true/false" */
            this.props.tracking(this.props.globalState.trackingData[0], this.props.globalState.trackingData[1], this.props.globalState.trackingData[2])
        }.bind(this), wait);
    }

    componentDidMount() {
        if (!this.props.globalState.isLive) {
            this.debug("start renderless component")
        }
    }

    componentDidUpdate(prevProps) {
        /* One globalState value to detect what was changed? trackingData? */
        if ( this.props.globalState.duration             !==    prevProps.globalState.duration             ||
             this.props.globalState.mileage              !==    prevProps.globalState.mileage              ||
             this.props.globalState.leasingSonderZahlung !==    prevProps.globalState.leasingSonderZahlung ||
             this.props.globalState.selectedModel        !==    prevProps.globalState.selectedModel        ||
             this.props.globalState.selectedList         !==    prevProps.globalState.selectedList            ) {
        
            clearTimeout(timeout);

            timeout = setTimeout(()=>{
                this.debug("update running")

                let t0 = performance.now();
                /* activate spinner */
                this.props.setGlobalState({showCalcSpinner: true});

                /* call data (product, callback, loadingIndicator, t0) */
                if (this.props.globalState.listBase === "electric_list") {
                    this.dataCall("PL", "dataLeasing","loadingLeasing", t0)
                    this.dataCall("PL_S", "dataPL_S","loadingPL_S", t0)
                    this.dataCall("PL_M", "dataPL_M","loadingPL_M", t0)
                    this.dataCall("PL_L", "dataPL_L","loadingPL_L", t0)
                }
                else {
                    this.dataCall(this.props.globalState.kunden ? "GL" : "PL", "dataLeasing", "loadingLeasing", t0)
                    this.dataCall("AC", "dataAC","loadingAC", t0)
                    this.dataCall("CC", "dataCC","loadingCC", t0)
                }
            }, (500))
        }
    }

    render() {
        return null;
    }
}
declare let module: object;

export default withGlobalState(hot(module)(UpdateProps));
