import * as React from "react"
import ReactDOM from 'react-dom'
import "../i18n"
import { Trans } from 'react-i18next'
import { hot } from "react-hot-loader"
import {
    Text,
    TextAppearance,
    Breakpoints,
    Container,
    ContainerPadding,
    ContainerWrap,
    CTA
} from "@volkswagen-onehub/components-core"
import { withGlobalState } from 'react-globally'
import FocusLock from "react-focus-lock";
import styled from "styled-components"
import { CloseCircle } from "@volkswagen-onehub/icons-core"

const StyledModal = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
    width: ${props => props.theme.size.grid024};
    height: 100%;
`

const StyledInnerModal = styled.div`
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    margin-left: ${props => props.theme.size.grid002};
    margin-right: ${props => props.theme.size.grid002};
    width: ${props => props.theme.size.grid020};
    height: calc(100% - ${props => props.theme.size.dynamic0300} - ${props => props.theme.size.dynamic0250});
    overflow: auto;
    margin-top: ${props => props.theme.size.dynamic0300};
    margin-bottom: ${props => props.theme.size.dynamic0250};
    @media (min-width: ${Breakpoints.b560}px) {
        width: ${props => props.theme.size.grid016};
        margin-left: ${props => props.theme.size.grid004};
        margin-right: ${props => props.theme.size.grid004};
	}
`

const StyledList = styled.div`
    overflow: auto;
`
const StyledText = styled.div`
border-bottom: 1px solid ${props => props.theme.border.color.primary};     
`;

const StyledIcon = styled.div`
    position: absolute;
    right: ${props => props.theme.size.static200};
    top: ${props => props.theme.size.static200};
`
const StyledCheckbox = styled.div`
    width: 24px;
`
const StyledContainer = styled.div`
    width: 100%;
    margin-top: ${props => props.theme.size.dynamic0100};
`
const StyledDivRow = styled.div`
    width: 100%;
`
const StyledDivCol1 = styled.div`
    display: inline-block;
    width: 10%;
    text-align: center;
`
const StyledDivCol2 = styled.div`
    display: inline-block;
    width: 30%;
    text-align: center;
`

class Modal extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    escapePress = (event) => {
        if (this.props.globalState.showInfo && (event.key === "Escape" || event.code === "Escape")) {
            this.props.setGlobalState({showInfo: false})
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', (event) => {this.escapePress(event)})
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', (event) => {this.escapePress(event)})
    }

    render() {
        let info_layer = (this.props.globalState.showInfo != false) ? this.props.globalState.showInfo : "leasing";
        let headline = "info_modal_"+info_layer+"_headline";
        let subline = "info_modal_"+info_layer+"_subline";
        let copy = "info_modal_"+info_layer+"_copy";
        let copy2 = "info_modal_"+info_layer+"_copy2" || false;
        let show_cta = (this.props.globalState.listBase === "electric_list") ? true : false;
        
        return ReactDOM.createPortal(
            <FocusLock
                disabled = { this.props.globalState.showInfo != false ? false : true }
                returnFocus = {true} >
            { this.props.globalState.showInfo != false && ( // do not use '== true' because this state is either 'false' or a string!
            <StyledModal>
                <StyledInnerModal>
                    
                    <StyledIcon className="close-modal">
                        <CTA
                            tag="button"
                            emphasis="tertiary"
                            onClick={() => this.props.setGlobalState({showInfo: false})}
                            ariaLabel="Fahrzeugauswahl schließen"
                            icon={<CloseCircle />}
                        />
                    </StyledIcon>

                    <Container stretchContent padding={{left: ContainerPadding.grid002, right: ContainerPadding.grid002}} wrap={ContainerWrap.always}>
                        <Container padding={{top: ContainerPadding.dynamic0200}}>
                            <Text appearance={TextAppearance.headline0300} bold={true}><Trans i18nKey={headline} /></Text>
                        </Container>
                        {subline.length > 0 &&
                            <Container padding={{top: ContainerPadding.static300}}>
                                <Text appearance={TextAppearance.copy0200}><Trans i18nKey={subline} /></Text>
                            </Container>
                        }
                        <Container padding={{top: ContainerPadding.static300}}>
                            <Text appearance={TextAppearance.copy0200}><Trans i18nKey={copy} /></Text>
                        </Container>

                        {info_layer === "lease_and_care" &&
                            <StyledContainer>
                                <StyledDivRow>
                                    <StyledDivCol1>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            &nbsp;
                                        </Container>
                                    </StyledDivCol1>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>Wartungen &amp; Inspektionen</Text>
                                        </Container>
                                    </StyledDivCol2>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>Wesentliche Verschleißreparaturen</Text>
                                        </Container>
                                    </StyledDivCol2>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>Winter-Komplett-Räder</Text>
                                        </Container>
                                    </StyledDivCol2>
                                </StyledDivRow>
                                
                                <StyledDivRow>
                                    <StyledDivCol1>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>S</Text>
                                        </Container>
                                    </StyledDivCol1>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>✓</Text>
                                        </Container>
                                    </StyledDivCol2>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>✘</Text>
                                        </Container>
                                    </StyledDivCol2>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>✘</Text>
                                        </Container>
                                    </StyledDivCol2>
                                </StyledDivRow>
                                
                                <StyledDivRow>
                                    <StyledDivCol1>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>M</Text>
                                        </Container>
                                    </StyledDivCol1>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>✓</Text>
                                        </Container>
                                    </StyledDivCol2>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>✓</Text>
                                        </Container>
                                    </StyledDivCol2>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>✘</Text>
                                        </Container>
                                    </StyledDivCol2>
                                </StyledDivRow>
                                
                                <StyledDivRow>
                                    <StyledDivCol1>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>L</Text>
                                        </Container>
                                    </StyledDivCol1>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>✓</Text>
                                        </Container>
                                    </StyledDivCol2>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>✓</Text>
                                        </Container>
                                    </StyledDivCol2>
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0200}>✓</Text>
                                        </Container>
                                    </StyledDivCol2>
                                </StyledDivRow>
                            </StyledContainer>
                        }

                        {(info_layer === "lease_and_care" && show_cta) &&
                        <Container padding={{top: ContainerPadding.static400, bottom: ContainerPadding.static500}}>
                            <StyledText>
                                <CTA
                                    tag="a"
                                    emphasis="tertiary"
                                    href="https://www.volkswagen.de/de/angebote-und-produkte/leasing/elektroauto-leasing/id-lease-and-care.html#leistungen"
                                    target={"_blank"}
                                    ariaLabel="Mehr über Lease&Care erfahren"
                                    onClick={() => { this.props.tracking("cta", this.props.globalState.dataLeasing, {url: "https://www.volkswagen.de/de/angebote-und-produkte/leasing/elektroauto-leasing/id-lease-and-care.html#leistungen", name: "InfoModal, Mehr über Lease&Care erfahren"}) }}
                                >
                                    <Trans i18nKey="to_the_configurator" />
                                </CTA>
                            </StyledText>
                        </Container>
                        }
                        
                        {(info_layer === "leasing" && show_cta ) &&
                        <Container padding={{top: ContainerPadding.static400, bottom: ContainerPadding.static500}}>
                            <StyledText>
                                <CTA
                                    tag="a"
                                    emphasis="tertiary"
                                    href="https://www.volkswagen.de/de/angebote-und-produkte/leasing.html"
                                    target={"_blank"}
                                    ariaLabel="Mehr über Lease&Care erfahren"
                                    onClick={() => { this.props.tracking("cta", this.props.globalState.dataLeasing, {url: "https://www.volkswagen.de/de/angebote-und-produkte/leasing.html", name: "InfoModal, Mehr über PrivatLeasing erfahren"}) }}
                                >
                                    <Trans i18nKey="to_the_configurator" />
                                </CTA>
                            </StyledText>
                        </Container>
                        }

                    </Container>
                </StyledInnerModal>
            </StyledModal>
            )}
            </FocusLock>,
            document.querySelector("body #reactmount [class^='StyledIntegratorRoot-']")
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(Modal));