import * as React from 'react';
import {Provider} from 'react-globally';

import {FeatureAppDefinition} from '@feature-hub/core';
import {ReactFeatureApp} from '@feature-hub/react';
import {ThemeProvider} from '@volkswagen-onehub/components-core';
import App from './components/App';
import i18next from 'i18next';

var getUrl = window.location;
var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];

let path = ""
let isLive = false
if (baseUrl.includes("localhost")){
    path = "https://prototype.de.ddb.com/fsag-calculator/img/"
}
else if (baseUrl.includes("lighthouselabs")){
    path = "https://prototype.de.ddb.com/fsag-calculator/img/"
}
else if (baseUrl.includes("volkswagen")){
    path = "https://finanzierungsrechner.vwfs.de/img/"
    isLive = true;
}

/* debug on dev & staging */
if (!isLive) {
    console.log("isLive: " + isLive)
    console.log("use img path: " + path)

    console.log("getUrl: " + getUrl)
    console.log("baseUrl: " + baseUrl)
}
const debug = (message:any) => {
    if(!isLive) console.log(message)
}

let listBase = 'vehicle_list'
let requestDomain = "VW.NEW"
let requestDomainElectric = "VW.ID.DE"

const initialState = {
    isLive: isLive,
    isInitialized: false,

    baseUrl: baseUrl,
    imagePath: path,

    usecase: "",

    tabSelectedIndex: 0,
    prevTabSelectedIndex: 0,
    vergleichen: false,

    listBase: listBase,
    kunden: false,

    showModal: false,
    showInfo: false,
    draggable: false,

    showCalcSpinner: false,

    calcErrored: false,

    err_test: false,

    requestDomain: requestDomain,
    requestDomainElectric: requestDomainElectric,
    flktySelectedIndex: 0,
    
    selectedModel: i18next.t(listBase)[0],
    selectedList: [0],
    prevSelectedList: [0],

    duration: 36,
    mileage: 15000,
    leasingSonderZahlung: 1000,

    loadingLeasing: true,
    dataLeasing: {},

    loadingAC: true,
    dataAC: {},
    loadingCC: true,
    dataCC: {},

    loadingLeaseCareS: true,
    dataPL_S: {},
    loadingLeaseCareM: true,
    dataPL_M: {},
    loadingLeaseCareL: true,
    dataPL_L: {},

    trackingData: ["calculate", {}, "Initial Setup", "initial call"],
    trackingService: null
};

const findCarId = (item:any) => {
    const carList: any = i18next.t(listBase);
    for (let i = 0; i < carList.length; i ++) {
        if (carList[i].name === item) {
            return i;
        }
    }
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp> = {
    dependencies: {
    featureServices: {
            "fa-content-service": "^1.0.0",
            "tracking": "^1.0.0"
        }
    },
  optionalDependencies: {
        featureServices: {
            "tracking": "^1.0.0",
            "locale-service": "^1.2.0"
        }
    },
    create: (env) => {
        debug(env.featureServices)

        // Handle locale service
        const localeService: any = env.featureServices["locale-service"]
        /* example result: {brand: "V", countryCode: "DE", currency: "EUR", direction: "ltr", language: "de", market: "DEU", marketType: "passenger"} */
        /* example result: {brand: "N", countryCode: "NL", currency: "EUR", direction: "ltr", language: "nl", market: "NLD", marketType: "commercial"} */
        if (localeService) {
            if (localeService.language === "nl") i18next.changeLanguage("nl")
            if (localeService.language === "de") i18next.changeLanguage("de")
            if (localeService.language === "en") i18next.changeLanguage("en")
        }

        // Handle predefined settings from content service
        const contentService: any = env.featureServices["fa-content-service"]
        if (contentService && contentService.content) {
            const preDefined = contentService.content

            // TBD: nfz + id in "usecase" vereinen!
            // get marketType from locale-service
            // how to handle electric vehicles?
            
            // if NFZ, load NFZ vehicle list // Nutzfahrzeuge
            if (preDefined.nfz === "nfz") {
                //console.log("featureService.content.nfr: " + preDefined.nfz + ", nfz_list triggered");
                listBase = "nfz_list"
                initialState.listBase = listBase;
                initialState.requestDomain = "VW.VWNFZ.ICC.PL";
                initialState.selectedModel = i18next.t(listBase)[0];
            }
            if (preDefined.nfz === "id") {
                //console.log("featureService.content.nfr: " + preDefined.nfz + ", electric_list triggered");
                listBase = "electric_list"
                initialState.listBase = listBase;
                initialState.requestDomain = "VW.NEW";
                initialState.selectedModel = i18next.t(listBase)[0];
            }
            // set preselected Tab
            if (preDefined.Solution === "Leasing") {
                initialState.tabSelectedIndex = 0;
                initialState.prevTabSelectedIndex = 0;
            }
            else if (preDefined.Solution === "Finanzierung") {
                initialState.tabSelectedIndex = 1;
                initialState.prevTabSelectedIndex = 1;
                
            }
            // get and replace duration time
            if (preDefined.Laufzeit) {
                initialState.duration = preDefined.Laufzeit;
            }
            // get and replace Downpay amount
            if (preDefined.Anzahlung) {
                initialState.leasingSonderZahlung = preDefined.Anzahlung;
            }
            // get and replace Mileage
            if (preDefined.Laufleistung) {
                initialState.mileage = preDefined.Laufleistung
            }
            // get Car Model Name, find ID in List, set as selected Model
            if (preDefined.Carmodel) {
                const preSetCars = preDefined.Carmodel.split(', ');
                initialState.selectedList = []
                preSetCars.forEach(car => {
                let pos = findCarId(car)
                initialState.selectedList.push(pos)
                });
                initialState.selectedModel = i18next.t(listBase)[initialState.selectedList[0]]
            }
        }
        
        // Handle tracking service
        const trackingService: any = env.featureServices["tracking"]
        if (trackingService) {
            initialState.trackingService = trackingService
        }

        return {
            render: () => (
                <Provider globalState={initialState}>
                    <ThemeProvider theme="main">
                        <App />
                    </ThemeProvider>
                </Provider>
            )
        };
    }
};

export default featureAppDefinition;
