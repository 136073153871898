import * as React from "react"
import "../i18n"
import { Trans }  from 'react-i18next'
import i18next from 'i18next'
import { hot } from "react-hot-loader"
import "../assets/scss/App.scss"
import {Info} from '@volkswagen-onehub/icons-core'
import {
    Text,
    TextAppearance,
    TextColor,
    ContainerPadding,
    ContainerHorizontalAlignment,
    Container,
    ContainerWrap,
    ContainerGutter,
    ContainerVerticalAlignment,
    Layout,
    Breakpoints,
    CTA
} from "@volkswagen-onehub/components-core"
import { withGlobalState } from 'react-globally'
import styled from "styled-components"
import InputSet from "./InputSet"

// const Styled = styled.div`
//   margin-left: ${(props) => props.theme.size.grid002};
//   margin-right: ${(props) => props.theme.size.grid002};
// `;

const StyledDivCol1 = styled.div`
    width: ${(props) => props.theme.size.grid006};
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${(props) => props.theme.size.grid003};
    }
`
const StyledDivCol2 = styled.div`
    width: ${(props) => props.theme.size.grid004};
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${(props) => props.theme.size.grid002};
    }
`

const StyledDiv1 = styled.div`
    padding-top: ${(props) => props.theme.size.dynamic0150};
    padding-left: ${(props) => props.theme.size.grid002};
    padding-right: ${(props) => props.theme.size.grid002};
    @media (min-width: ${Breakpoints.b960}px) {
        padding-left: ${(props) => props.theme.size.grid002};
        padding-right: ${(props) => props.theme.size.grid001};
    }
`

const StyledDiv2 = styled.div`
    padding-top: ${(props) => props.theme.size.dynamic0150};
    padding-left: ${(props) => props.theme.size.grid002};
    padding-right: ${(props) => props.theme.size.grid002};
    @media (min-width: ${Breakpoints.b960}px) {
        padding-left: ${(props) => props.theme.size.grid001};
        padding-right: ${(props) => props.theme.size.grid001};
    }
`

const StyledDivColRecommend = styled.div`
    width: ${(props) => props.theme.size.grid004};
    color: #00b0f0;
    fill: #00b0f0;
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${(props) => props.theme.size.grid002};
    }
`

const HeartIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path d="M6.121 4.416A4.468 4.468 0 0 1 9.3 5.74l1.802 1.801.963.963.94-.985 1.697-1.78a4.473 4.473 0 0 1 5.17-.851 3.925 3.925 0 0 1 1.94 2.346 3.803 3.803 0 0 1-.212 2.937c-1.592 2.94-7.254 9.413-9.557 9.413-2.383 0-8.213-6.776-9.641-9.413a3.803 3.803 0 0 1-.213-2.937 3.925 3.925 0 0 1 1.94-2.346 4.48 4.48 0 0 1 1.993-.47m11.758-1.33a5.813 5.813 0 0 0-4.119 1.711L12.042 6.6 10.24 4.798a5.803 5.803 0 0 0-6.707-1.1 5.349 5.349 0 0 0-2.302 7.106c1.301 2.402 7.474 10.11 10.81 10.11 3.325 0 9.427-7.708 10.728-10.11a5.349 5.349 0 0 0-2.302-7.107 5.782 5.782 0 0 0-2.588-.61z"></path>
        </svg>
    )
}

class LeaseCare extends React.Component<any, any> {
    constructor(props) {
        super(props);     
    }

    formatNumber(numberString) {
        // remove currency symbol
        numberString = numberString.replace(/€/g, "");
        // turn any decimal comma into a dot for further editing
        numberString = numberString.replace(/,/g, ".");
        // turn string into actual number
        let newNumber = parseFloat(numberString).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        return newNumber;
    }

    render() {
        let leasing_rate_offer_disclaimer = "leasing_rate_offer_disclaimer";
        return (
            <Container stretchContent>
            <Container wrap={ContainerWrap.always} stretchContent padding={{bottom: ContainerPadding.dynamic0250}}>

                {((this.props.globalState.loadingLeaseCareS === true ) || (this.props.globalState.loadingLeaseCareM === true) || (this.props.globalState.loadingLeaseCareL === true)) &&
                    <Container padding={{top: ContainerPadding.static400}} horizontalAlign={ContainerHorizontalAlignment.center} verticalAlign={ContainerVerticalAlignment.flexEnd} gutter={ContainerGutter.dynamic0040} wrap={ContainerWrap.never}>
                        <Text appearance={TextAppearance.headline0250} bold={true}>Loading</Text>
                    </Container>
                }
                
                {((this.props.globalState.loadingLeaseCareS === false ) && (this.props.globalState.loadingLeaseCareM === false) && (this.props.globalState.loadingLeaseCareL === false)) &&
                <Container padding={{top: ContainerPadding.static400}} horizontalAlign={ContainerHorizontalAlignment.center} verticalAlign={ContainerVerticalAlignment.flexEnd} gutter={ContainerGutter.dynamic0040} wrap={ContainerWrap.never}>
                    <Text appearance={TextAppearance.headline0250} bold={true}><Trans i18nKey="lease_and_care" /></Text>
                    <CTA
                        tag="button"
                        emphasis="tertiary"
                        onClick={() => { this.props.setGlobalState({showInfo:"lease_and_care"})}}
                        ariaLabel="Info"
                        size="small"
                        icon={<Info />}
                    >
                    </CTA>
                </Container>
                }

                {((this.props.globalState.loadingLeaseCareS === false )&& (this.props.globalState.loadingLeaseCareM === false) && (this.props.globalState.loadingLeaseCareL === false)) &&

                <Layout appearance={{
                    [Breakpoints.default]: [
                        {name: 'a', columns: 24},
                        {name: 'b', columns: 24},
                    ],
                    [Breakpoints.b960]: [ 
                        {name: 'a', columns: 12},
                        {name: 'b', columns: 12},
                    ]
                }}>
                    <Container stretchContent horizontalAlign={ContainerHorizontalAlignment.center}>
                        <StyledDiv1>
                            <Container wrap={ContainerWrap.always}>
                                {/* Switch Private/Business Customer 
                                <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                    <Container verticalAlign={ContainerVerticalAlignment.flexEnd} gutter={ContainerGutter.dynamic0040} wrap={ContainerWrap.never} >
                                        <Text appearance={TextAppearance.label0200}><Trans i18nKey="private_customer" /></Text>
                                        <Switch className={'react-switch'} onChange={() => { this.props.setGlobalState({ kunden : !this.props.globalState.kunden }); }} checked={this.props.globalState.kunden} uncheckedIcon={false} checkedIcon={false} height={24} width={60} offColor="#001E50" onColor="#001E50" offHandleColor="#fff" onHandleColor="#fff" handleDiameter={16}/>
                                        <Text appearance={TextAppearance.label0200}><Trans i18nKey="business_customer" /></Text>
                                    </Container>
                                </Container>
                                */}

                                <InputSet
                                    scope = "Lease&Care"
                                    downPaymentTitle = {i18next.t('leasing_downpayment')}
                                    trackingMessage = "Lease&Care neu berechnen"
                                    trackingProduct = {this.props.globalState.dataLeasing}
                                />

                            </Container>
                        </StyledDiv1>
                    </Container>

                    <Container stretchContent horizontalAlign={ContainerHorizontalAlignment.center}>
                        <StyledDiv2>
                            <Container wrap={ContainerWrap.always}>
                                
                                {/* Recommendation */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            &nbsp;
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                &nbsp;
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivColRecommend>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Container gutter={ContainerGutter.static200} verticalAlign={ContainerVerticalAlignment.center} horizontalAlign={ContainerHorizontalAlignment.center} wrap={ContainerWrap.never}>
                                                <HeartIcon /><Text appearance={TextAppearance.label100} bold={true} color={TextColor.inherit}>{i18next.t("recommended_product")}</Text>
                                            </Container>
                                        </Container>
                                    </StyledDivColRecommend>
                                    
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                &nbsp;
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>

                                {/* headlines */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            &nbsp;
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0200} bold>{i18next.t("lease_and_care_package_s")}</Text>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0200} bold>{i18next.t("lease_and_care_package_m")}</Text>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                    
                                    <StyledDivCol2>
                                        <Container padding={{bottom: ContainerPadding.static200}} wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0200} bold>{i18next.t("lease_and_care_package_l")}</Text>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>
                                
                                {/* headlines */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{"Wartungen & Inspektionen"}</Text>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0150}>{"✓"}</Text>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0150}>{"✓"}</Text>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                    
                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0150}>{"✓"}</Text>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>
                                
                                {/* headlines */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{"Wesentliche Verschleißreparaturen"}</Text>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0150}>{"✘"}</Text>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0150}>{"✓"}</Text>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                    
                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0150}>{"✓"}</Text>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>
                                
                                {/* headlines */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{"Winterkompletträder"}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static300}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0150}>{"✘"}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static300}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0150}>{"✘"}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static300}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                    
                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                                                <Text appearance={TextAppearance.copy0150}>{"✓"}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static300}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>

                                {/* Netto sum 
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("net_credit_amount_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_S["Response"]["Calculation"]["NetCreditAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_M["Response"]["Calculation"]["NetCreditAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_L["Response"]["Calculation"]["NetCreditAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>*/}

                                {/* Interest Nominal 
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_nominal_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_S["Response"]["Calculation"]["InterestNominal"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_M["Response"]["Calculation"]["InterestNominal"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_L["Response"]["Calculation"]["InterestNominal"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>*/}
                                
                                {/* Interest 
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_S["Response"]["Calculation"]["Interest"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_M["Response"]["Calculation"]["Interest"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_L["Response"]["Calculation"]["Interest"])} %</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>*/}

                                {/* Leasing Sum 
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("loan_amount")}</Text>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_S["Response"]["Calculation"]["LoadAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                    
                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_M["Response"]["Calculation"]["LoadAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                    
                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataPL_L["Response"]["Calculation"]["LoadAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                <div className="divider-custom-vw-blue"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>*/}

                                {/* Monthly Rate */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <StyledDivCol1>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy0150} bold>{this.props.globalState.dataPL_S["Response"]["Calculation"]["PaymentCount"]} {i18next.t("monthly_rate_label")}</Text>
                                            <Container padding={{top: ContainerPadding.static200}} stretchContent>
                                                <div className="divider-custom-vw-blue-thick"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol1>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150} bold>{this.formatNumber(this.props.globalState.dataPL_S["Response"]["Calculation"]["TotalGrossAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200}} stretchContent>
                                                <div className="divider-custom-vw-blue-thick"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150} bold>{this.formatNumber(this.props.globalState.dataPL_M["Response"]["Calculation"]["TotalGrossAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200}} stretchContent>
                                                <div className="divider-custom-vw-blue-thick"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>

                                    <StyledDivCol2>
                                        <Container wrap={ContainerWrap.always}>
                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                <Text appearance={TextAppearance.copy0150} bold>{this.formatNumber(this.props.globalState.dataPL_L["Response"]["Calculation"]["TotalGrossAmount"])} {i18next.t("currency_label")}</Text>
                                            </Container>
                                            <Container padding={{top: ContainerPadding.static200}} stretchContent>
                                                <div className="divider-custom-vw-blue-thick"></div>
                                            </Container>
                                        </Container>
                                    </StyledDivCol2>
                                </Container>

                                {/* Disclaimer */}
                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                    <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}}>
                                        <Container wrap={ContainerWrap.always}>
                                            <Text appearance={TextAppearance.copy150} color={TextColor.secondary}><Trans i18nKey={leasing_rate_offer_disclaimer} /></Text>
                                        </Container>
                                    </Container>
                                </Container>
                                
                                <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                    <CTA
                                        tag="button"
                                        emphasis="secondary"
                                        onClick={() => {
                                            this.props.setGlobalState({tabSelectedIndex: 2})
                                            this.props.setGlobalState({vergleichen: true})
                                            this.props.tracking("compare", this.props.globalState.dataLeasing, "Finanzierungen vergleichen")
                                        }}
                                        ariaLabel={i18next.t('compare_financing')}
                                    >
                                        <Trans i18nKey="compare_financing" />
                                    </CTA>
                                </Container>

                            </Container>
                        </StyledDiv2>
                    </Container>
                </Layout>

                }
            </Container>
            </Container>
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(LeaseCare));
