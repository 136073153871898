import * as React from "react"
import {
    Container,
    ContainerHorizontalAlignment,
    ContainerPadding,
    ContainerWrap,
    Slider,
    Text,
    TextAppearance
} from "@volkswagen-onehub/components-core"

export default (props) => {
    const title  = props.title
    const initial = props.initial 
    const min    = props.min
    const max    = props.max
    const steps  = props.steps
    const info   = props.info
    const aria   = props.aria
    const change = props.change

    return (

        <React.Fragment>

            <Container horizontalAlign={ContainerHorizontalAlignment.center}>
                <Text appearance={TextAppearance.headline0200} bold>{title}</Text>
            </Container>

            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent wrap={ContainerWrap.always}>

                <Slider
                    min  = {min}
                    max  = {max}
                    step = {steps}
                    defaultValue = {initial}
                    ariaLabel    = {aria}
                    getValueText = {value => `${value.toLocaleString()} ${info}`}
                    onChange     ={value => {change(value)}}
                />

            </Container>

        </React.Fragment>
        
    );
};
