import * as React from "react"
import ReactDOM from 'react-dom'
import "../i18n"
import { Trans } from 'react-i18next'
import { hot } from "react-hot-loader"
import i18next from 'i18next'
import {
    Text,
    TextAppearance,
    Container,
    ContainerPadding,
    ContainerWrap,
    ContainerHorizontalAlignment,
    ThemeProvider,
    CTA
} from "@volkswagen-onehub/components-core"
import { withGlobalState } from 'react-globally';
import FocusLock from "react-focus-lock";
import styled from "styled-components";

import CarImage from '../components/CarImages';

const StyledModal = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 8;
    position: fixed;
    left: 0;
    top: 0;
    width: ${props => props.theme.size.grid024};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledInnerModal = styled.div`
    position: relative;
    background-color: rgba(91, 8, 164, 1);
    margin-top: ${props => props.theme.size.static0400};
    max-width: 550px;
`
 
const StyledIcon = styled.div`
    width: 48px;
    margin-right: ${props => props.theme.size.static0250}
`
const StyledText = styled.div`
    max-width: 170px;
`

class CalcErrModal extends React.Component<any, any> {
    
    constructor(props) {
        super(props);
    }

    escapePress = (event) => {
        if (this.props.globalState.calcErrored && (event.key === "Escape" || event.code === "Escape")) {
            this.props.setGlobalState({ calcErrored: false })
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', (event) => {this.escapePress(event)})
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', (event) => {this.escapePress(event)})
    }

    render() {
        return ReactDOM.createPortal(
            <FocusLock
                disabled = {!this.props.globalState.calcErrored} 
                returnFocus = {true} >
            { this.props.globalState.calcErrored != false && ( // do not use '== true' because this state is either 'false' or a string!
            <StyledModal>
                <StyledInnerModal>
                    
                    <Container stretchContent padding={ContainerPadding.static400} wrap={ContainerWrap.always}>

                        <Container stretchContent wrap={ContainerWrap.never}>
                            <Container padding={{right:ContainerPadding.static400}}>
                                <StyledIcon>
                                    <CarImage source="alert" alt=""/>
                                </StyledIcon>
                            </Container>
                            <StyledText>
                                <ThemeProvider theme="inverted">
                                    <Text appearance={TextAppearance.label0200}><Trans i18nKey="calc_error" /></Text>
                                </ThemeProvider>
                            </StyledText>
                             
                        </Container>

                        <Container padding={{top: ContainerPadding.static400}} horizontalAlign={ContainerHorizontalAlignment.center}>
                            
                            <ThemeProvider theme="inverted">
                                <CTA
                                    tag="button"
                                    emphasis="primary"
                                    onClick={() => this.props.setGlobalState({ calcErrored: false })}
                                    ariaLabel={i18next.t('calc_error_button')}
                                >
                                    <Trans i18nKey="calc_error_button" />
                                </CTA>
                            </ThemeProvider>

                        </Container>

                    </Container>
                </StyledInnerModal>
            </StyledModal>
            )}
            </FocusLock>,
            document.querySelector("body #reactmount [class^='StyledIntegratorRoot-']")
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(CalcErrModal));