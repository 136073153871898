import * as React from "react"
import {useState} from "react"
import NumberFormat from 'react-number-format'
import {
    Container,
    ContainerHorizontalAlignment,
    ContainerPadding,
    ContainerWrap,
    Text,
    TextAppearance,
    TextInput,
    InputAppearance
} from "@volkswagen-onehub/components-core"


export default (props) => {
    const title = props.title
    const value = props.value 
    const max = props.max /* Price of car - or defined max value, e.g. 2/3 of it */
    const change = props.change

    const [appearance, setAppearance] = useState(
        InputAppearance.Default
    )
    const [message, setMessage] = useState(undefined)

    return (

        <React.Fragment>

            <Container padding={{top: ContainerPadding.static400}} horizontalAlign={ContainerHorizontalAlignment.center}>
                <Text appearance={TextAppearance.headline0200} bold>{title}</Text>
            </Container>

            <Container padding={{top: ContainerPadding.static350}} stretchContent wrap={ContainerWrap.always}>
                
                <NumberFormat
                    /* Use TextInput from OneHub components-core */
                    customInput={TextInput}
                    appearance={appearance}
                    message={message}
                    
                    /* Value */
                    value={value}
                    min={0}
                    max={max}
                    decimalSeparator={','}
                    thousandSeparator={'.'}
                    /*suffix={',00'}*/

                    /* A11Y */
                    inputMode={"numeric"}

                    /* Function */
                    onValueChange={ e => {
                        change({ leasingSonderZahlung: parseInt(e["value"]) })
                    }}

                    onChange={event => {
                        /*if (event.currentTarget.value === "666") {
                            console.log("test")
                            setAppearance(InputAppearance.Error);
                            setMessage("Evil Bastard");
                            return;
                        }*/
                        if (parseInt(event.currentTarget.value.replace(".", "")) >= max) {
                            //console.log("zu groß (" + parseInt(event.currentTarget.value.replace(".", "")) + " vs. " + max +")")
                            setAppearance(InputAppearance.Error);
                            setMessage("Die Anzahlung ist größer als der Nettodarlehensbetrag/Anschaffungspreis.");
                            return;
                        }
                        if (event.currentTarget.value.length === undefined) {
                            change({ leasingSonderZahlung: 0 })
                            setAppearance(InputAppearance.Default);
                            setMessage(undefined);
                            return;
                        }
                        setAppearance(InputAppearance.Default);
                        setMessage(undefined);
                    }}
                />

            </Container>

        </React.Fragment>
        
    );
};
