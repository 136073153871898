import * as React from "react"
import ReactDOM from 'react-dom'
import "../i18n"
import { Trans } from 'react-i18next'
import { hot } from "react-hot-loader"
import i18next from 'i18next'
import {
    Text,
    TextAppearance,
    Breakpoints,
    Container,
    ContainerPadding,
    ContainerWrap,
    ContainerHorizontalAlignment,
    Checkbox,
    CTA
} from "@volkswagen-onehub/components-core"
import { withGlobalState } from 'react-globally'
import FocusLock from "react-focus-lock";
import styled from "styled-components"
import { CloseCircle } from "@volkswagen-onehub/icons-core"

const StyledModal = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
    width: ${props => props.theme.size.grid024};
    height: 100%;
`

const StyledInnerModal = styled.div`
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    margin-left: ${props => props.theme.size.grid002};
    margin-right: ${props => props.theme.size.grid002};
    width: ${props => props.theme.size.grid020};
    height: calc(100% - ${props => props.theme.size.dynamic0250} - ${props => props.theme.size.dynamic0250});
    overflow: auto;
    margin-top: ${props => props.theme.size.dynamic0250};
    margin-bottom: ${props => props.theme.size.dynamic0250};
    @media (min-width: ${Breakpoints.b560}px) {
        width: ${props => props.theme.size.grid016};
        margin-left: ${props => props.theme.size.grid004};
        margin-right: ${props => props.theme.size.grid004};
	}
`
const StyledCloseContainer = styled.div`
    position: absolute;
    right: ${props => props.theme.size.static350};
    top: ${props => props.theme.size.static200};
    width: 24px;
    height: 24px;
`

const StyledIcon = styled.div`
    position: fixed;
    
`

class Modal extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            selectedList: this.props.globalState.selectedList
        }
    }
    requestDomain = this.props.globalState.requestDomain;
    requestDomainElectric = this.props.globalState.requestDomainElectric;

    debug = (message: any) => {
        if (!this.props.isLive) console.log(message)
    }

    createList = () => {
        let vehicles = i18next.t(this.props.globalState.listBase);
        let selectedList = this.props.globalState.selectedList
        let children = []
        for (let i = 0; i < vehicles.length; i++) {
            
            children.push(
                <Container key={i} horizontalAlign={ContainerHorizontalAlignment.center} wrap={ContainerWrap.always}>
                    <Checkbox
                        disabled = {((selectedList.length <= 1 && selectedList.indexOf(i) != -1 ) || (selectedList.length >= 6 && selectedList.indexOf(i) == -1 )) ? true : false}
                        checked  = {selectedList.indexOf(i) == -1 ? false : true}
                        value    = {String(i)}
                        label    = {
                            <Container padding={{bottom:ContainerPadding.static250}}>
                                <Text appearance={TextAppearance.copy0200} >{vehicles[i]["name_label"]}</Text>
                            </Container>
                        }
                        onClick  = { e => {
                            /* Is the vehicle already in the selectedList? */
                            let pos = selectedList.indexOf(i);
                            if (pos == -1) {
                                /* No, to list. */
                                selectedList.push(i);
                            }
                            else {
                                /* Yes, remove from list. */
                                selectedList.splice(pos, 1);
                            }
                            /* Update Global List. */
                            this.setState({selectedList: selectedList});
                        }}
                    />
                </Container>
            )
        }
        return children
    }

    handleClose = () => {
        this.debug("modal closed")
        // has the ordered selectedList changed?
        let updated = this.props.globalState.prevSelectedList.sort().toString() !== this.state.selectedList.sort().toString() ? true : false
        this.debug("updated? " + updated)
        
        /* track only if updated were made */
        if (updated) {
            this.props.setGlobalState({ trackingData: ["add_vehicle", false, "Fahrzeugliste ändern", "update list"] })

            this.props.setGlobalState({ selectedList: this.state.selectedList})
            // update the selected model only if the selected model is not in the new selected list
            // tbd: selectedModel still changes even though it is in the list
            // find new index of selected model?
            this.debug("selected model:")
            this.debug(this.props.globalState.selectedModel)
            if (this.state.selectedList.indexOf(this.props.globalState.selectedModel) == -1) {
                this.debug("update selected model")
                this.props.setGlobalState({ selectedModel: i18next.t(this.props.globalState.listBase)[this.state.selectedList[0]] })
            }
        }
        this.props.setGlobalState({ prevSelectedList: this.props.globalState.selectedList })
        this.props.setGlobalState({ showModal: false })
    }

    escapePress = (event) => {
        if (this.props.globalState.showModal && (event.key === "Escape" || event.code === "Escape")) {
            this.handleClose()
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', (event) => {this.escapePress(event)})
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', (event) => {this.escapePress(event)})
    }

    render() {
        
        return ReactDOM.createPortal(
            <FocusLock
                disabled={!this.props.globalState.showModal}
                returnFocus = {true} >
            { this.props.globalState.showModal && ( 
            <StyledModal>
                <StyledInnerModal>

                    <Container stretchContent padding={{left: ContainerPadding.grid002, right: ContainerPadding.grid002}} wrap={ContainerWrap.always}>

                        <Container padding={{top: ContainerPadding.dynamic0100}} horizontalAlign={ContainerHorizontalAlignment.center}>
                            <Text appearance={TextAppearance.headline0300} bold={true}><Trans i18nKey="car_selection" /></Text>
                        </Container>

                        <Container padding={{top: ContainerPadding.static200}} horizontalAlign={ContainerHorizontalAlignment.center}>
                            <Text appearance={TextAppearance.copy0200}><Trans i18nKey="car_selection_copy" /></Text>
                        </Container>

                        <Container  padding={{top: ContainerPadding.dynamic0130}} wrap={ContainerWrap.always} stretchContent> 
                            {this.createList()}
                        </Container>

                        <Container padding={{top: ContainerPadding.dynamic0250, bottom:ContainerPadding.dynamic0250}}  horizontalAlign={ContainerHorizontalAlignment.center} >
                            <CTA
                                tag="button"
                                emphasis="primary"
                                onClick={() => this.handleClose()}
                                ariaLabel={i18next.t('update_vehicles')}
                            >
                                <Trans i18nKey="update_vehicles" />
                            </CTA>
                        </Container>
                    </Container>
                    
                    <StyledCloseContainer>
                        <StyledIcon className="close-modal">
                            <CTA
                                tag="button"
                                emphasis="tertiary"
                                onClick={() => this.handleClose()}
                                ariaLabel="Fahrzeugauswahl schließen"
                                icon={<CloseCircle />}
                            />
                        </StyledIcon>
                    </StyledCloseContainer>
                    
                </StyledInnerModal>
            </StyledModal>
            )}
            </FocusLock>,
            document.querySelector("body #reactmount [class^='StyledIntegratorRoot-']")
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(Modal));