import * as React from "react"
import { hot } from "react-hot-loader"
import { withGlobalState } from 'react-globally'
import {
    Image
} from "@volkswagen-onehub/components-core"

class CarImage extends React.Component<any, any> {

    constructor(props) {
        super(props);
    }

    render() {

        let source = this.props.source;
        let alttext = this.props.alttext;

        let placeholder = (this.props.globalState.listBase === "nfz_list") ? "nfz.png" : (this.props.globalState.listBase === "electric_list") ? "id.png" : "car.png";
        
        /*const path = process.env.REACT_APP_IMAGE_PATH;*/
        /*const path = this.props.globalState.isLive ? "https://finanzierungsrechner.vwfs.de/img/" : "https://prototype.de.ddb.com/fsag-calculator/img/";*/
        /*const path = this.props.globalState.imagePath;*/
        // To Be Changed: Get ENV PATH

        if (source === "alert") {
            const alert = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABPJJREFUaAXtmVuIVVUYx/9rphknvJRdLKMLpUVkjhQhRFHmYA89ZASRBEYPJSEFJihdKLqJmaY+9lJmIBJkRPVQdB9FFLScM1MJOY1llEpgzhg65jmr31pnHzn7zN6z9z5zzswEZx8We6/13f7ft9b61uVIjacRgUYE/tcRMPVAb3s0R3m9gO47ZNQqq71q0mozW5/X2l7NHbBd6gDkx5RzQ2ANbhgtxYk3Q+0jrDSNUD4kbvdrMg2bKQ78ezpHsyhXAXyNh2+10f6k60JC46lic1pDD1jeO60FetlD26aA9klZ8/j5BOA1lFOUgu3WLZXI7AFNg3bcO9GtBZX0auu1HELrGCYTALKZcb6nEpCZqaPQX/XtBa2nh5orecasTsTnBcNjwP6o6XFA7M+aQC/0Br3weBzfqLYTySZA7fOguvRsknGcvT/gPWp7dV4Sf93pgF8SADpo+9SWxiAy3wYya9Pw142HITEFIEc8mJweSGsI/ptxIk8ZpEdmpJWrOR9A1gbgO6OUQ9tG+TSSltPbgewHUfS6t7nI+Qi6SBLRKIO0n/YgrVoq6W6yQzvh6TndWUlPW68+jVq9QVpsxdA7Zo6+S2uwxGdu0J98r/Z1qw0uGZRoWd5VCdkfNB/wC9kinCCbP5fFYIj3Aq1Hx2+03aQePRKipaxkdsAvQHltCPSvMrN0OKWtIWzmCp0kEE97AoscgZk0hCmhIbMD6tajGG1Hbx9btpIjCWbiyQy/rfTCLnROV0HPxHNGUzI54Bceq1e8qmatMNdqMFptxtZmLcMJy285vXBlFulMDjDin0f5xRjrNDdqWxZDw/EyDHdD34oDbTrD1jvDk9oBIjMT4E9SCkzcZRlspGM1fi6chHkR6fnWdELKkLryQdq02kTEvk9rIC0fO9hDBGed53cHH3d+S/Gk6gEWmw66915UDuBy9WkzCdA0P3z+gG0uyeKhJHZHT3TAp01zNtusIlJH0iiuhsdcqn+QK+1oX7OHKs7VEUoTHWCBWUL0ZxP9X1C3MUJHbZva9S629mLzch3TiiTlwzrA9vh8puzLXompYdocBpUpptOnPIvVSi4KLhuGPWEIDfi7nYuIyDcMnVHbNbK4bcfm+wCfyEpT3C/FeBHbA/76w+oJFLm0WYxIjJK6NLdqJbYHKYvZ+Q65JCjZjHVAp0mb8tvgt0ib+0oCmd5GXwCgk2HxbyY5mM31bFXEnHPptHA2iaRTg8cLgn36cXcdkk6q9lxVnfhc2gR8j3egKzkL1B52WCOr8mMBlj53qxGmRtRgXuoFcjrA9sEdWMb0IaDu1qMrwFTceschcmkT5r8C5vvi+NK0u0UIPTsou0d6fYKOjgBTP8P7knL74Uncz8y3uhCGr027PixnzPx9TPOQuY0yl/X17szyZQKk1S+Zyh+BbTIltJUJO2D0oJczerFMvrrPVu3A6H5KL7nsq+qUlElZveRrNsAYkEI7PoaPO+K1aYqmmqv1d5n4mH/6+ZjXKfAZzoMtpOYzDlS4B6RfPdJ+/lkZf8/tHrzR7yXwDmLYAeP/nGAV4dIpp4V4PcmnVZdax6oc1kSw3MNfVlt8TEsYgwCHh5C7gMrpM2h3BfTx9XKH/6ma728zohxwbUS6hcPEcj4fpsyg20L/tDieUX2KY/0gNrewnX/d1OoiYVSdaBhrRKARgdgI/AcYHhLTflf05wAAAABJRU5ErkJggg==";
            return (
                <Image src={alert} alt={alttext}/>
            );
        }
        else if (source === "placeholder") {
            const images = require.context('../assets/img', true);
            const imagePath = (images(`./${placeholder}`).default).replace("./img/", this.props.globalState.imagePath);
            return (
                <Image src={imagePath} alt={alttext}/>
            );
        }
        else {
            const images = require.context('../assets/img', true);
            const imagePath = (images(`./${source}`).default).replace("./img/", this.props.globalState.imagePath);
            return (
                <Image src={imagePath} alt={alttext}/>
            );
        }
    }
}
declare let module: object;

export default withGlobalState(hot(module)(CarImage));
