import "../assets/scss/App.scss"
import "../assets/scss/Flickity.scss"
import "../assets/scss/Print.scss"
import * as React from "react"
import { withGlobalState } from 'react-globally'
import { hot } from "react-hot-loader"
import "../i18n"
import i18next from 'i18next'
import Header from "./Header"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Leasing from "./Leasing"
import LeasingId from "./LeasingId"
import Footer from "./Footer"
import Vergleichen from "./Vergleichen"
import VergleichenId from "./VergleichenId"
import Modal from './Modal'
import InfoModal from './InfoModal'
import Finanzierung from "./Finanzierung"
import LeaseCare from "./LeaseCare"
import CalcErrModal from "./CalcErrModal"
import CalcSpinner from "./CalcSpinner"
import { Trans } from 'react-i18next'
import {
    Container,
    Breakpoints,
    ContainerWrap,
    ContainerPadding,
    ContainerHorizontalAlignment,
    Divider,
    Layout,
    styled,
    Text,
    TextAppearance,
    TextColor
} from "@volkswagen-onehub/components-core"
import UpdateProps from "./UpdateProps"

const StyledDivider = styled.div`
    margin-left: ${(props) => props.theme.size.grid001};
    width: ${(props) => props.theme.size.grid022};
`
const StyledContainer = styled.div`
    min-height: 1000px;
`

class App extends React.Component<any, any> {

    constructor(props) {
        super(props);
    }
    trackingService = this.props.globalState.trackingService;

    debug = (message: any) => {
        if (!this.props.isLive) console.log(message)
    }

    formatNumber(n) {
        // format number 1000000 to 1,234,567
        return n.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    updateData(requestDomain, leasingType, mileage, duration, downPayment, key, year, priceModel, priceTotal) {
        let opts;
        if(priceModel === priceTotal) {
            opts = {
            "Request": {
                "@Domain": requestDomain,
                "@Name": "Defaults",
                "Product": {
                    "@ID": leasingType,
                    "Parameter": [
                        {
                            "@ID": "Mileage",
                            "#text": mileage
                        },
                        {
                            "@ID": "Duration",
                            "@Changed": "true",
                            "#text": duration
                        },
                        {
                            "@ID": "DownPayment",
                            "#text": downPayment
                        }
                    ]
                },
                "Vehicle": {
                    "Key": key,
                    "Year": year,
                    "PriceModel": priceModel,
                    "PriceTotal": priceTotal,
                }
            }
            }
        }
        else {
            opts = {
                "Request": {
                    "@Domain": requestDomain,
                    "@Name": "Defaults",
                    "Product": {
                        "@ID": leasingType,
                        "Parameter": [
                            {
                                "@ID": "Mileage",
                                "#text": mileage
                            },
                            {
                                "@ID": "Duration",
                                "@Changed": "true",
                                "#text": duration
                            },
                            {
                                "@ID": "DownPayment",
                                "#text": downPayment
                            }
                        ]
                    },
                    "Vehicle": {
                        "Key": key,
                        "Year": year,
                        "PriceModel": priceModel,
                        "PriceTotal": priceTotal,
                        "Options": {
                            "Option": [
                                {"@ID": "GYCRYCR", "@Code": "YPU", "@FinanceType": "Aktionspakete", "@Price": "-3570"}
                            ]
                        }
                    }
                }
            }
        }
        return new Promise((resolve, reject) => {

            fetch('https://api.webcalc.vwfs.io/webcalc-frontend-service', {
                method: 'post',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(opts)
            })
            .then(response => response.json())
            .then((data) => {

                if (data["Response"]["Error"]) {
                    //alert(`Error Description - ${data["Response"]["Error"]["Description"]}`)
                        console.error("Error", data["Response"]["Error"])
                    reject(data["Response"]["Error"])
                } else {
                    resolve(data)
                    // move "Remove Spinner" here
                    //console.log(data)
                } 
            })
            .catch(error => {
                console.error("Error", error)
                reject(error)
            });

        })

    }

    tracking = (call, product, link) => {
        // get product data from the current active tab, if it is not provided, for example by specific InputSets
        product = product ? product : this.props.globalState.listBase === "electric_list" ? (this.props.globalState.tabSelectedIndex === 0 ? this.props.globalState.dataLeasing : this.props.globalState.dataPL_M) : (this.props.globalState.tabSelectedIndex === 0 ? this.props.globalState.dataLeasing : this.props.globalState.dataAC)

        this.debug("tracking call: " + call + ", link: " + link)

        /* Vehicle List */
        let current_vehicle = this.props.globalState.selectedModel
        let vehicles = i18next.t(this.props.globalState.listBase)
        let selectedList = this.props.globalState.selectedList
        let vehicleArray = []

        for (let i = 0; i < selectedList.length; i++) {
            vehicleArray.push({
                "CarlineName": vehicles[selectedList[i]]["name"],
                "ModelId": vehicles[selectedList[i]]["key"]
            })
        }

        /* currently selected vehicle */
        let configuration = {
            BodyTypeName: current_vehicle["body_type"] || undefined,
            CarlineId: current_vehicle["carline_id"] || undefined,
            CarlineName: current_vehicle["name"],
            SalesgroupId: current_vehicle["salesgroup_id"] || undefined,
            SalesgroupName: current_vehicle["name_label"],
            ModelId: current_vehicle["model_id"],
            ModelName: current_vehicle["title"],
            vehicleList: vehicleArray
        }

        /* cta, compare */
        let calculation_l = this.props.globalState.listBase === "electric_list" ? {
            ProductName: product["Response"]["Calculation"]["Product"],
            ProductType: product["Response"]["Calculation"]["ProductType"],
            FinancingCurrency: "EUR",
            FinancingCashPrice: product["Response"]["Calculation"]["NetCreditAmount"],
            FinancingTotalSum: product["Response"]["Calculation"]["GrossCreditAmount"],
            FinancingDownpaymentValue: product["Response"]["Calculation"]["DownPayment"],
            FinancingMonthlyPayment: product["Response"]["Calculation"]["MonthlyLeaseRateGros"],
            FinancingFinalPayment: product["Response"]["Calculation"]["FinalPayment"],
            FinancingInterestEffective: product["Response"]["Calculation"]["Interest"],
            FinancingInterestDebt: product["Response"]["Calculation"]["InterestNominal"],
            FinancingDuration: product["Response"]["Calculation"]["Duration"],
            FinancingMileageNumber: product["Response"]["Calculation"]["Mileage"],
            FinancingMileageUnit: "km"
        } : {
            ProductName: product["Response"]["Tracking"]["Product"]["Name"],
            ProductType: product["Response"]["Tracking"]["Product"]["Type"],
            FinancingCurrency: product["Response"]["Tracking"]["Financing"]["Currency"],
            FinancingCashPrice: product["Response"]["Tracking"]["Financing"]["CashPrice"],
            FinancingTotalSum: product["Response"]["Tracking"]["Financing"]["TotalSum"],
            FinancingDownpaymentValue: product["Response"]["Tracking"]["Financing"]["DownPayment"]["Value"],
            FinancingMonthlyPayment: product["Response"]["Tracking"]["Financing"]["MonthlyPayment"],
            FinancingFinalPayment: product["Response"]["Tracking"]["Financing"]["FinalPayment"],
            FinancingInterestEffective: product["Response"]["Tracking"]["Financing"]["Interest"]["Effective"],
            FinancingInterestDebt: product["Response"]["Tracking"]["Financing"]["Interest"]["Dept"],
            FinancingDuration: product["Response"]["Tracking"]["Financing"]["Duration"],
            FinancingMileageNumber: product["Response"]["Tracking"]["Financing"]["Mileage"]["Number"],
            FinancingMileageUnit: product["Response"]["Tracking"]["Financing"]["Mileage"]["Unit"]
        }

        /* calculate leasing/financing */
        let calculation_m = this.props.globalState.listBase === "electric_list" ? {
            ProductName: product["Response"]["Calculation"]["Product"],
            ProductType: product["Response"]["Calculation"]["ProductType"],
            FinancingCurrency: "EUR",
            FinancingCashPrice: product["Response"]["Calculation"]["NetCreditAmount"],
            FinancingDownpaymentValue: product["Response"]["Calculation"]["DownPayment"],
            FinancingDuration: product["Response"]["Calculation"]["Duration"],
            FinancingMileageNumber: product["Response"]["Calculation"]["Mileage"],
            FinancingMileageUnit: "km"
        } : {
            ProductName: product["Response"]["Tracking"]["Product"]["Name"],
            ProductType: product["Response"]["Tracking"]["Product"]["Type"],
            FinancingCurrency: product["Response"]["Tracking"]["Financing"]["Currency"],
            FinancingCashPrice: product["Response"]["Tracking"]["Financing"]["CashPrice"],
            FinancingDownpaymentValue: product["Response"]["Tracking"]["Financing"]["DownPayment"]["Value"],
            FinancingDuration: product["Response"]["Tracking"]["Financing"]["Duration"],
            FinancingMileageNumber: product["Response"]["Tracking"]["Financing"]["Mileage"]["Number"],
            FinancingMileageUnit: product["Response"]["Tracking"]["Financing"]["Mileage"]["Unit"]
        }

        /* comparison table */
        let calculation_s = this.props.globalState.listBase === "electric_list" ? {
            FinancingCurrency: "EUR",
            FinancingCashPrice: product["Response"]["Calculation"]["NetCreditAmount"],
            FinancingDownpaymentValue: product["Response"]["Calculation"]["DownPayment"],
            FinancingDuration: product["Response"]["Calculation"]["Duration"]
        } : {
            FinancingCurrency: product["Response"]["Tracking"]["Financing"]["Currency"],
            FinancingCashPrice: product["Response"]["Tracking"]["Financing"]["CashPrice"],
            FinancingDownpaymentValue: product["Response"]["Tracking"]["Financing"]["DownPayment"]["Value"],
            FinancingDuration: product["Response"]["Tracking"]["Financing"]["Duration"],
        }

        if (this.trackingService) {

            /*
            * Handle tracking calls
            */
            switch (call) {
                case "cta":
                    this.trackingService.track('VWBasic_CTA_Click',
                        {
                            configuration: configuration,
                            link: {
                                url: link["url"],
                                name: link["name"]
                            },
                            calculation: calculation_l
                        }
                    )
                    break;

                case "add_vehicle":
                    this.trackingService.track('VWBasic_AddModelsToComparison_Click',
                        {
                            configuration: {
                                vehicleList: vehicleArray
                            },
                                link:{
                                name: link
                            }
                        }
                    )
                    break;

                case "calculate":
                    this.trackingService.track('VWBasic_CalculateFinancing_Click',
                        {
                            configuration: configuration,
                                link: {
                                name: link
                            },
                            calculation: calculation_m
                        }
                    )
                    break;

                case "compare":
                    this.trackingService.track('VWBasic_CompareFinancing_Click',
                        {
                            configuration: configuration,
                            link: {
                                name: link
                            },
                            calculation: calculation_l
                        }
                    )
                    break;

                case "table":
                    this.trackingService.track('VWBasic_FinancialComparisonTable_Load',
                        {
                            configuration: configuration,
                            calculation: calculation_s
                        }
                    )
                    break;
            }
        }
    }

    render() {

        return (
            <StyledContainer>
                <UpdateProps updateData={this.updateData} tracking={this.tracking}/>

                <Container padding={{top: ContainerPadding.dynamic0250, bottom: ContainerPadding.dynamic0250}} wrap={ContainerWrap.always}>
                    <Header updateData={this.updateData} tracking={this.tracking}/>

                    <Tabs selectedIndex={this.props.globalState.tabSelectedIndex}
                        onSelect={(index: Number) => {
                        this.props.setGlobalState({tabSelectedIndex: index});
                        this.props.setGlobalState({prevTabSelectedIndex: index});
                        this.props.setGlobalState({vergleichen: false});
                        }}
                    >
                        <Layout appearance={{
                            [Breakpoints.default]: [
                                {name: '.', columns: 2},
                                {name: 'a', columns: 20},
                                {name: '.', columns: 2},
                                
                            ],
                            [Breakpoints.b960]: [
                                {name: '.', columns: 1},
                                {name: 'a', columns: 22},
                                {name: '.', columns: 1},
                            ],
                        }}>

                            <Container wrap={ContainerWrap.never} stretchContent>
                                <TabList>
                                    <Tab className={"app-tab " + (this.props.globalState.tabSelectedIndex == 0 ? 'app-tab-active' : '')}>
                                    <Container horizontalAlign={ContainerHorizontalAlignment.center} padding={{top: ContainerPadding.dynamic0150, bottom: ContainerPadding.static300}}>
                                        {this.props.globalState.listBase == "electric_list"
                                        ? <Text appearance={TextAppearance.headline0300} color={TextColor.inherit} bold={true}><Trans i18nKey="lease_and_care" /></Text>
                                        : <Text appearance={TextAppearance.headline0300} color={TextColor.inherit} bold={true}><Trans i18nKey="leasing" /></Text>
                                        }
                                    </Container>
                                    </Tab>
                                    <Tab className={"app-tab " + (this.props.globalState.tabSelectedIndex == 1 ? 'app-tab-active' : '')}>
                                    <Container horizontalAlign={ContainerHorizontalAlignment.center} padding={{top: ContainerPadding.dynamic0150, bottom: ContainerPadding.static300}}>
                                        {this.props.globalState.listBase == "electric_list"
                                        ? <Text appearance={TextAppearance.headline0300} color={TextColor.inherit} bold={true}><Trans i18nKey="leasing" /></Text>
                                        : <Text appearance={TextAppearance.headline0300} color={TextColor.inherit} bold={true}><Trans i18nKey="financing" /></Text>
                                        }
                                        </Container>
                                    </Tab>
                                </TabList>
                            </Container>
                        </Layout>

                        <TabPanel>
                            {this.props.globalState.listBase === "electric_list"
                            ? <LeaseCare updateData={this.updateData} formatNumber={this.formatNumber} tracking={this.tracking}/>
                            : <Leasing updateData={this.updateData} formatNumber={this.formatNumber} tracking={this.tracking}/>
                            }
                        </TabPanel>

                        <TabPanel>
                            {this.props.globalState.listBase === "electric_list"
                            ? <LeasingId updateData={this.updateData} formatNumber={this.formatNumber} tracking={this.tracking}/>
                            : <Finanzierung updateData={this.updateData} formatNumber={this.formatNumber} tracking={this.tracking}/>
                            }
                        </TabPanel>
                        
                    </Tabs>

                    {this.props.globalState.vergleichen == true &&
                        <> {/* ID List shows different results */}
                        {this.props.globalState.listBase === "electric_list"
                            ? <VergleichenId formatNumber={this.formatNumber} tracking={this.tracking}/> 
                            : <Vergleichen formatNumber={this.formatNumber} tracking={this.tracking}/> 
                        }
                        </>
                    }

                    <Modal/>
                    <InfoModal tracking={this.tracking} />
                    <CalcErrModal />

                    <StyledDivider>
                        <Divider></Divider>
                    </StyledDivider>

                    <Footer/>

                    {/*this.props.globalState.showCalcSpinner == true &&
                        <CalcSpinner />
                    */}

                </Container>
            </StyledContainer>
        );
    }
}

declare let module: object;

export default withGlobalState(hot(module)(App));
