
import * as React from "react"
import "../i18n"
import i18next from 'i18next'
import { hot } from "react-hot-loader"
import "../assets/scss/App.scss"
/*import {
    Text,
    TextAppearance,
    TextColor,
    ContainerPadding,
    ContainerHorizontalAlignment,
    Container,
    ContainerWrap,
    ContainerGutter,
    ContainerVerticalAlignment,
    Layout,
    Breakpoints,
    CTA
} from "@volkswagen-onehub/components-core"*/
import { withGlobalState } from 'react-globally'
import InputField from "./InputField"
import InputSlider from "./InputSlider"


class InputSet extends React.Component<any, any> {
    constructor(props) {
        super(props);     
    }

    trackingAction = "calculate"
    //product data = false = product from the active tab will be used for tracking
    forTracking = [this.trackingAction, this.props.trackingProduct ? this.props.trackingProduct : false, this.props.trackingMessage, this.props.scope]

    render() {
        return(
            <React.Fragment>

                {/* Duration Input */}
                <InputSlider
                    title  = {i18next.t('duration')}
                    initial = {this.props.globalState.duration}
                    min    = {12}
                    max    = {48}
                    steps  = {6}
                    info   = {i18next.t('months')}
                    aria   = {i18next.t('duration')}
                    change = { (val) => {this.props.setGlobalState({ duration: val }); this.props.setGlobalState({ trackingData: this.forTracking }); }}
                />

                {/* Milage Input */}
                {this.props.scope !== "ClassicCredit" ?
                    <InputSlider
                        title  = {i18next.t('annual_milage')}
                        initial = {this.props.globalState.mileage}
                        min    = {10000}
                        max    = {100000}
                        steps  = {1000}
                        info   = {i18next.t('milage_short')}
                        aria   = {i18next.t('annual_milage')}
                        change = {(val) => {this.props.setGlobalState({ mileage: val }); this.props.setGlobalState({ trackingData: this.forTracking }); }}
                    />
                : null
                }

                {/* Downpayment Input */}
                <InputField
                    title   = {this.props.downPaymentTitle}
                    value   = {this.props.globalState.leasingSonderZahlung}
                    max     = {this.props.globalState.selectedModel.price}
                    change  = { (val) => {this.props.setGlobalState(val); this.props.setGlobalState({ lastChanged: this.forTracking }); }}
                />

            </React.Fragment>
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(InputSet));